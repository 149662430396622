import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Box } from "@mui/material";

function FormStepOne({ values, onNext }) {
  const formik = useFormik({
    initialValues: {
      firstName: values.firstName || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
    }),
    onSubmit: (formValues) => {
      onNext(formValues);
    },
  });

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="userDocumentBox">
            <div className="Userdocument">
              <h6>User Document </h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Pariatur ad eos magni reprehenderit sequi possimus quas
                veritatis, nobis quibusdam ab autem minima similique nesciunt
                obcaecati dignissimos fuga velit debitis enim.lorem23 Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Nobis nulla,
                molestiae eaque vero dolore aliquid quis ipsam id maiores magni
                fugiat quisquam ea dolorem, omnis consectetur ut maxime
                voluptatem. Error repudiandae sapiente unde veniam pariatur
                eveniet perspiciatis laboriosam totam repellendus accusamus
                dolores similique, rem officiis, assumenda molestias earum
                ducimus, delectus aspernatur. Cupiditate quibusdam officia
                explicabo. Nesciunt enim ab ullam minus tempora cumque
                consequuntur, inventore excepturi aspernatur perspiciatis nemo
                est doloribus quam odio odit repudiandae iusto saepe delectus
                corporis placeat, iure, ipsum blanditiis dolor illum? Possimus
                nihil explicabo ab nemo magnam, delectus doloribus ea illum amet
                veritatis beatae ipsa iste nam?
              </p>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <div className="row">
            <div className="col-lg-4 col-md-6-col-sm-12 mb-3">
              {" "}
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </div>
          </div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <button className="AllsiteButton mt-4" type="submit" name="submit">
            NEXT
          </button>
        </Box>
      </form>
    </>
  );
}

export default FormStepOne;
