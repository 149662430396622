import React from "react";
import Sidebar from "./Sidebar";
import DeshBoardFooter from "./DeshBoardFooter";
// import LeadBox from "./LeadBox";
import { AllDeshbordCountData, AllserviceLeads } from "../FetchAPI";
import { Link } from "react-router-dom";

export const Deshbard = ({ ShowUserSideBar, RendomTokenfetch }) => {
  const [UserLead] = AllserviceLeads(RendomTokenfetch, "5", "1");
  const [DeshbordCount] = AllDeshbordCountData(RendomTokenfetch);

  // console.log(DeshbordCount);
  // const ShowNotfoundlead = (UserLeadPera, leadStatus, leadmsg) => {
  //   let ShowLeaddata;
  //   if (leadStatus === "0") {
  //     ShowLeaddata = (
  //       <>
  //         <div className="leadNotfountbox">
  //           <p>{leadmsg}</p>
  //         </div>
  //       </>
  //     );
  //   } else {
  //     ShowLeaddata = (
  //       <>
  //         <div className="whiteBoxOuter">
  //           <div className="row">
  //             {UserLeadPera &&
  //               UserLeadPera.map((value, index) => (
  //                 <LeadBox key={index} leaddata={value} />
  //               ))}
  //           </div>
  //         </div>
  //       </>
  //     );
  //   }
  //   return ShowLeaddata;
  // };

  // console.log(RendomTokenfetch);
  return (
    <>
      <div className="page-wrapper dashboard mm-page mm-slideout" id="mm-0">
        <div className="sidebar-backdrop"></div>
        <Sidebar ShowUserSideBar={ShowUserSideBar} />
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="row">
              {DeshbordCount &&
                DeshbordCount.map((value, index) => (
                  <div
                    key={index}
                    className="col-xl-3 col-lg-6 col-md-6 col-sm-12"
                  >
                    <Link to={`/${value.redirect_link}`}>
                    <div className="ui-item">
                      <div className="left">
                        <i
                          className={`icon ${value.icon}`}
                          style={{ color: value.color }}
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="right">
                        <h4 style={{ color: value.color }}>{value.counter}</h4>
                        <p>{value.name}</p>
                      </div>
                    </div>
                    </Link>
                  </div>
                ))}
            </div>
            <div className="row mt-4">
              <div className="col-lg-12 col-md-12-col-sm-12">
                {/* {ShowNotfoundlead(UserLead, Status, msg)} */}
                {UserLead && (
                  <div className="intro">
                    <div className=" orderleadbox ">
                      <h3 className="mb-0">My Services</h3>
                    </div>
                    <div className="orderleadtable">
                      <table className="default-table manage-job-table">
                        <thead>
                          <tr>
                            <th>Ticket ID</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Service</th>
                            <th>Status</th>
                            <th>Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {UserLead &&
                            UserLead.map((value, index) => (
                              <tr key={index}>
                                <td>
                                  {" "}
                                  <Link to={`/service-booking/${value.id}`}>
                                    {value.id}
                                  </Link>
                                </td>
                                <td>
                                  <Link to={`/service-booking/${value.id}`}>
                                    <img
                                      src={value.service_icon}
                                      alt=""
                                      style={{ width: "40px" }}
                                    />
                                  </Link>
                                </td>
                                <td>
                                  <Link to={`/service-booking/${value.id}`}>
                                    {value.name}
                                  </Link>
                                </td>
                                <td>{value.service}</td>
                                <td>
                                  <span className="badge bg-primary">
                                    {value.status}
                                  </span>
                                </td>
                                <td>{value.date}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="col-lg-8 col-md-12-col-sm-12"></div> */}
            </div>
          </div>
        </section>
        <div className="deshboard_footer">
          <DeshBoardFooter RendomTokenfetch={RendomTokenfetch} />
        </div>
      </div>
    </>
  );
};
