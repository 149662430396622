import React from "react";
import OwlCarousel from "react-owl-carousel";
import WhyChoose from "./WhyChoose/WhyChoose";
import {
  Homesetting,
  Homedefaultserviceplan,
  HomePlanstabs,
  HomeBlog,
} from "./FetchAPI";
import HomeMetaData from "./HomeMetaData";
import BlogPageBox from "./Blog/BlogPageBox";
import HowitWork from "./HowItWork/HowitWork";
import ServicePlanBox from "./Service/ServicePlanBox";
import HomePlansServiceBox from "./HomePlansServiceBox";
import ServiceSection from "./Service/ServiceSection";
import { Link } from "react-router-dom";

export default function Home({ RendomTokenfetch }) {
  // const [ServiceAll] = AllServices();
  const ServiceAll = ['Name Change','Passport Apply','Marriage Certificate','Arya Samaj Marriage','Court Marriage'];
  const [SettingHome] = Homesetting(RendomTokenfetch);
  // console.log(SettingHome.category_section1);
  const defaultServiceid = SettingHome.service_default;
  const [Defaultservice, Defaultplan] =
    Homedefaultserviceplan(defaultServiceid);
  const [ServicePlanTabs] = HomePlanstabs();

  const [Blogs, Loading] = HomeBlog();

  const options = {
    loop: true,
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    navText: ["&lt;", ">"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  const clientOwl = {
    loop: true,
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    navText: ["&lt;", ">"],
    smartSpeed: 1000,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };

  return (
    <>
      <HomeMetaData />
      {/* Banner Section Start */}
      <section className="banner-section-ten">
        <OwlCarousel
          className="banner-carousel owl-carousel owl-theme default-nav"
          {...options}
        >
          <div className="slide-item bg-image item">
            <img
              src="images/background/7.jpg"
              alt="banner"
              style={{ height: "100%" }}
            />
          </div>
          <div className="slide-item bg-image item">
            <img
              src="images/background/8.png"
              alt="banner"
              style={{ height: "100%" }}
            />
          </div>
        </OwlCarousel>

        <div className="auto-container">
          <div className="cotnent-box">
            <div className="title-box wow fadeInUp" data-wow-delay="500ms">
              <h3>Ready to Live Smarter?</h3>
              <p className="text-light">
                Get instant access to reliable and affordable services
              </p>
            </div>

            <div
              className="job-search-form wow fadeInUp"
              data-wow-delay="1000ms"
            >
              <form>
                <div className="row">
                  <div className="form-group col-lg-4 col-md-12 col-sm-12">
                    <span className="icon">
                      {" "}
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </span>

                    <input
                      type="text"
                      name="field_name"
                      placeholder="Service title or keywords"
                      defaultValue="Search..."
                    />
                  </div>

                  <div className="form-group col-lg-3 col-md-12 col-sm-12 location">
                    <span className="icon">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                    </span>
                    <input
                      type="text"
                      name="field_name"
                      placeholder="City or postcode"
                      defaultValue="Search..."
                    />
                  </div>

                  <div className="form-group col-lg-3 col-md-12 col-sm-12 category">
                    <span className="icon">
                      <i className="fa fa-briefcase" aria-hidden="true"></i>
                    </span>
                    <select className="chosen-select">
                      <option value="">Select Service</option>
                      {ServiceAll &&
                        ServiceAll.map((Allvalue,index) => (
                          <option key={index} value={Allvalue}>
                            {Allvalue}
                          </option>
                        ))}
                      {/* <option value="43">Vehicle Care</option>
                      <option value="44">Accounting / Finance</option>
                      <option value="106">Automotive service</option>
                      <option value="46">Customer</option>
                      <option value="48">Design</option>
                      <option value="47">Development</option>
                      <option value="45">Health and Care</option>
                      <option value="105">Marketing</option>
                      <option value="107">Project Management</option> */}
                    </select>
                  </div>

                  <div className="form-group col-lg-2 col-md-12 col-sm-12 text-right">
                    <button type="submit" className="theme-btn btn-style-one">
                      Find Service
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="top-features HomebannerBox">
              <div className="row wow fadeInUp">
                <div className="feature-block-two col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box">
                    <span className="icon">
                      <i className="fa fa-file-text" aria-hidden="true"></i>
                    </span>
                    <h4>Document Services</h4>
                    <span className="count">(100+ More Sercices)</span>
                  </div>
                </div>

                <div className="feature-block-two col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box">
                    <span className="icon">
                      <i className="fa fa-home" aria-hidden="true"></i>
                    </span>
                    <h4>Home/Personal Care Services</h4>
                    <span className="count">(20+ More Services)</span>
                  </div>
                </div>

                <div className="feature-block-two col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box">
                    <span className="icon ">
                      <i className="fa fa-file-code" aria-hidden="true"></i>
                    </span>
                    <h4>IT Services</h4>
                    <span className="count">(12+ More Services)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section End */}
      {/* How it Work  */}
      <HowitWork />
      {/* End How it works? */}
      {/* Services Categories 1 */}
      <ServiceSection
        RendomTokenfetch={RendomTokenfetch}
        catid={SettingHome.category_section1}
        servicelimit={SettingHome.category_section_limit1}
        limittype={"1"}
        bgColor={"bgc-home20"}
      />
      {/* Services End 1*/}
      {/* Why Chhose us start */}
      <WhyChoose />
      {/* Why Chhose us End */}
      {/* Start About Section */}-
      <section
        id="section-1"
        className="bgc-home20 about-section-two style-two js-tab-menu-content"
      >
        <div className="auto-container">
          <div className="row grid-base justify-content-between align-items-center">
            <div className="content-column col-xl-4 col-lg-5 col-md-12 col-sm-12 order-2 order-lg-1">
              <div className="inner-column -no-padding wow fadeInLeft">
                <div className="sec-title">
                  <h2 className="fw-700">
                    Awesome features to make your life easier
                  </h2>
                  {/* <div className="text mt-30">To start searching for Service, you can attend job fairs online or in person, use job boards and career websites or reach out directly to recruiters in a targeted company to broaden your network.</div> */}
                </div>
                <ul className="sectionlistStyle mt-24 mb-24">
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i> Select
                    instructor based on your chosen schedule
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i>Pickup service
                    option available
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i>Track your
                    Service schedule
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i>Assistance
                    with issuance of Service schedule
                  </li>
                </ul>
                <Link to="/" className="theme-btn -blue">
                  Ready More
                </Link>
              </div>
            </div>
            <div className="image-column -no-margin col-lg-6 col-md-12 col-sm-12 order-1 order-lg-2 wow fadeInRight">
              <figure className="image-box">
                <img src="images/index-12/images/1.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* End About Section */}
      {/* Services Categories 2 */}
      <ServiceSection
        RendomTokenfetch={RendomTokenfetch}
        catid={SettingHome.category_section2}
        servicelimit={SettingHome.category_section_limit2}
        limittype={"1"}
      />
      {/* Services End 2*/}
      {/* About Section */}
      <section
        id="section-2"
        className="about-section-two bgc-home20 style-two layout-pt-60 layout-pb-60 js-tab-menu-content"
      >
        <div className="auto-container">
          <div className="row grid-base align-items-center">
            <div className="image-column -no-margin col-xl-5 col-lg-6 col-md-12 col-sm-12 wow fadeInRight">
              <figure className="image-box">
                <img src="images/index-12/images/2.png" alt="" />
              </figure>
            </div>

            <div className="content-column col-xl-4 offset-xl-2 col-lg-5 offset-lg-1 col-md-12 col-sm-12">
              <div className="content-column">
                <div className="inner-column -no-padding wow fadeInLeft">
                  <div className="sec-title">
                    <h2 className="color-blue-dark fw-700">
                      REGISTRATIONS & LICENSES for
                      <br /> Your business
                    </h2>
                  </div>
                  <div className="mt-30 mb-30">
                    <div className="icon-item">
                      <div className="icon-wrap -red">
                        <img src="images/index-12/icons/1.svg" alt="icon" />
                      </div>
                      <div className="content p-0 mt-0">
                        <h4>Private Limited Company</h4>
                        <p>
                          Duis aute irure dolor reprehen derit in
                          <br /> volu velit.
                        </p>
                      </div>
                    </div>

                    <div className="icon-item">
                      <div className="icon-wrap -green">
                        <img src="images/index-12/icons/2.svg" alt="icon" />
                      </div>
                      <div className="content p-0 mt-0">
                        <h4>One Person Company</h4>
                        <p>
                          Duis aute irure dolor reprehen derit in
                          <br /> volu velit.
                        </p>
                      </div>
                    </div>
                  </div>
                  <Link to="/" className="theme-btn -blue-light">
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End About Section */}
      {/* Testimonial Section */}
      <section
        className="testimonial-section style-two alternate section_padding_top section_padding_bottom"
        style={{ backgroundImage: "url(images/background/9.png)" }}
      >
        <div className="auto-container">
          <div className="sec-title text-center light">
            <h2>Testimonials From Our Customers</h2>
            {/* <div className="text text-white">
              Lorem ipsum dolor sit amet elit, sed do eiusmod tempor
            </div> */}
          </div>

          <div className="carousel-outer wow fadeInUp">
            <OwlCarousel
              className="testimonial-carousel-three owl-carousel owl-theme default-dots light"
              {...clientOwl}
            >
              <div className="testimonial-block item">
                <div className="inner-box">
                  <h4 className="title">Good Experience</h4>
                  <div className="text">
                    Without serviceHunt i’d be homeless, they found me a service
                    and got me sorted out quickly with everything! Can’t quite…
                    The Mitech team works really hard to ensure high level of
                    quality
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src="images/resource/testi-thumb-1.png" alt="" />
                    </div>
                    <h4 className="name">Nicole Wells</h4>
                    <span className="designation">Web Developer</span>
                  </div>
                </div>
              </div>

              <div className="testimonial-block item">
                <div className="inner-box">
                  <h4 className="title">Great quality!</h4>
                  <div className="text">
                    Without serviceHunt i’d be homeless, they found me a service
                    and got me sorted out quickly with everything! Can’t quite…
                    The Mitech team works really hard to ensure high level of
                    quality
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src="images/resource/testi-thumb-2.png" alt="" />
                    </div>
                    <h4 className="name">Gabriel Nolan</h4>
                    <span className="designation">Consultant</span>
                  </div>
                </div>
              </div>

              <div className="testimonial-block item">
                <div className="inner-box">
                  <h4 className="title">Good Experience</h4>
                  <div className="text">
                    Without serviceHunt i’d be homeless, they found me a service
                    and got me sorted out quickly with everything! Can’t quite…
                    The Mitech team works really hard to ensure high level of
                    quality
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src="images/resource/testi-thumb-1.png" alt="" />
                    </div>
                    <h4 className="name">Nicole Wells</h4>
                    <span className="designation">Web Developer</span>
                  </div>
                </div>
              </div>

              <div className="testimonial-block item">
                <div className="inner-box">
                  <h4 className="title">Great quality!</h4>
                  <div className="text">
                    Without serviceHunt i’d be homeless, they found me a service
                    and got me sorted out quickly with everything! Can’t quite…
                    The Mitech team works really hard to ensure high level of
                    quality
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src="images/resource/testi-thumb-2.png" alt="" />
                    </div>
                    <h4 className="name">Gabriel Nolan</h4>
                    <span className="designation">Consultant</span>
                  </div>
                </div>
              </div>

              <div className="testimonial-block item">
                <div className="inner-box">
                  <h4 className="title">Good Experience</h4>
                  <div className="text">
                    Without serviceHunt i’d be homeless, they found me a service
                    and got me sorted out quickly with everything! Can’t quite…
                    The Mitech team works really hard to ensure high level of
                    quality
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src="images/resource/testi-thumb-1.png" alt="" />
                    </div>
                    <h4 className="name">Nicole Wells</h4>
                    <span className="designation">Web Developer</span>
                  </div>
                </div>
              </div>

              <div className="testimonial-block item">
                <div className="inner-box">
                  <h4 className="title">Great quality!</h4>
                  <div className="text">
                    Without serviceHunt i’d be homeless, they found me a service
                    and got me sorted out quickly with everything! Can’t quite…
                    The Mitech team works really hard to ensure high level of
                    quality
                  </div>
                  <div className="info-box">
                    <div className="thumb">
                      <img src="images/resource/testi-thumb-2.png" alt="" />
                    </div>
                    <h4 className="name">Gabriel Nolan</h4>
                    <span className="designation">Consultant</span>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* End Testimonial Section  */}
      {/* Services Categories 3 */}
      <ServiceSection
        RendomTokenfetch={RendomTokenfetch}
        catid={SettingHome.category_section3}
        servicelimit={SettingHome.category_section_limit3}
        limittype={"1"}
      />
      {/* Services End 3*/}
      {/*trust-security start */}
      <section
        id="trust-security"
        className="section_padding_top section_padding_bottom"
      >
        <div className="container text-center">
          {/* <div className="row">
            <div className="col-md-4">
              <hr className="before_border devider_border_color" />
            </div>
            <div className="col-md-4">
              <div className="section_title ">
                <h2 className="title textLight">Your Trust and Security</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border devider_border_color" />
            </div>
          </div> */}
          <div className="sec-title text-center">
            <h2 className="text-light">Your Trust and Security</h2>
          </div>

          <div className="row text-left">
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/time.png" alt="SAVES" />
                </div>
                <div className="box_content">
                  <h4>SAVES YOU TIME</h4>
                  <p>
                    We helps you live smarter, giving you time to focus on
                    what's most important.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/Safety.png" alt="Safety" />
                </div>
                <div className="box_content">
                  <h4>For Your Safety</h4>
                  <p>
                    All of our Helpers undergo rigorous identity checks and
                    personal interviews. Your safety is even our concern too.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/best.png" alt="Best" />
                </div>
                <div className="box_content">
                  <h4>Best-Rated Professionals</h4>
                  <p>
                    Our experienced taskers perform their tasks with dedication
                    and perfection. We appreciate your reviews about the
                    service.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/Equipped.png" alt="Equipped" />
                </div>
                <div className="box_content">
                  <h4>We Are Well Equipped</h4>
                  <p>
                    Let us know if you have any specific equirement, otherwise
                    our guys carry their own supplies.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/touch.png" alt="Always" />
                </div>
                <div className="box_content">
                  <h4>Always In Touch</h4>
                  <p>
                    Book your service online on one tap, keep a track of your
                    service status and also keep in touch with your Helper.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="icon_box_one">
                <div className="icons">
                  <img src="images/cash.png" alt="cash" />
                </div>
                <div className="box_content">
                  <h4>Cash-Free Facility</h4>
                  <p>
                    Pay through secure online mode only after your job is done.
                  </p>
                  <a href="/" className="read-more">
                    Read More{" "}
                    <span className="glyphicon glyphicon-menu-right"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*trust-security End */}
      {/* Pricing Sectioin */}
      <section className="pricing-section section_padding_top section_padding_bottom">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>Pricing Packages</h2>
            <div className="text">
              dolor sit amet elit, sed do eiusmod tempor.
            </div>
          </div>

          <div className="pricing-tabs tabs-box">
            <ul className="TAB_BTNul" role="tablist">
              <li className="tab-btn">
                <a
                  className="active"
                  data-bs-toggle="pill"
                  href={`#${defaultServiceid}`}
                >
                  {Defaultservice.title}
                </a>
              </li>
              {ServicePlanTabs &&
                ServicePlanTabs.map((value1, index) => (
                  <li key={index} className="tab-btn">
                    <a
                      className=""
                      data-bs-toggle="pill"
                      href={`#${value1.id}`}
                    >
                      {value1.title}
                    </a>
                  </li>
                ))}
            </ul>

            <div className="tab-content wow fadeInUp">
              <div className="tab-pane active" id={`${defaultServiceid}`}>
                <div className="content">
                  <div className="row">
                    {Defaultplan &&
                      Defaultplan.map((value, index) => (
                        <ServicePlanBox
                          key={index}
                          planid={value.id}
                          title={value.title}
                          selling_price={value.selling_price}
                          price={value.price}
                          plan_type={value.plan_type}
                          service_tag={value.service_tag}
                        />
                      ))}
                  </div>
                </div>
              </div>

              {ServicePlanTabs &&
                ServicePlanTabs.map((value2, index) => (
                  <div key={index} className="tab-pane" id={`${value2.id}`}>
                    <div className="content">
                      <div className="row">
                        <HomePlansServiceBox IDService={value2.id} />
                      </div>
                    </div>
                  </div>
                ))}

              {/* <div className="tab-pane" id="document" fade="true">
                <div className="content">
                  <div className="row">
                    <div className="pricing-table col-lg-4 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="title">Basic</div>
                        <div className="price">
                          $1199 <span className="duration">/ Annual</span>
                        </div>
                        <div className="table-content">
                          <ul>
                            <li>
                              <span>1 service posting</span>
                            </li>
                            <li>
                              <span>0 featured service</span>
                            </li>
                            <li>
                              <span>service displayed for 20 days</span>
                            </li>
                            <li>
                              <span>Premium Support 24/7 </span>
                            </li>
                          </ul>
                        </div>
                        <div className="table-footer">
                          <a href="/" className="theme-btn btn-style-three">
                            View Profile
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="pricing-table tagged col-lg-4 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <span className="tag">Recommended</span>
                        <div className="title">Standard</div>
                        <div className="price">
                          $1499 <span className="duration">/ Annual</span>
                        </div>
                        <div className="table-content">
                          <ul>
                            <li>
                              <span>1 service posting</span>
                            </li>
                            <li>
                              <span>0 featured service</span>
                            </li>
                            <li>
                              <span>service displayed for 20 days</span>
                            </li>
                            <li>
                              <span>Premium Support 24/7 </span>
                            </li>
                          </ul>
                        </div>
                        <div className="table-footer">
                          <a href="/" className="theme-btn btn-style-three">
                            View Profile
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="homecare">
                <div className="content">
                  <div className="row">
                    <div className="pricing-table col-lg-4 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="title">Basic</div>
                        <div className="price">
                          $199 <span className="duration">/ monthly</span>
                        </div>
                        <div className="table-content">
                          <ul>
                            <li>
                              <span>1 service posting</span>
                            </li>
                            <li>
                              <span>0 featured service</span>
                            </li>
                            <li>
                              <span>service displayed for 20 days</span>
                            </li>
                            <li>
                              <span>Premium Support 24/7 </span>
                            </li>
                          </ul>
                        </div>
                        <div className="table-footer">
                          <a href="/" className="theme-btn btn-style-three">
                            View Profile
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="pricing-table tagged col-lg-4 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <span className="tag">Recommended</span>
                        <div className="title">Standard</div>
                        <div className="price">
                          $499 <span className="duration">/ monthly</span>
                        </div>
                        <div className="table-content">
                          <ul>
                            <li>
                              <span>1 service posting</span>
                            </li>
                            <li>
                              <span>0 featured service</span>
                            </li>
                            <li>
                              <span>service displayed for 20 days</span>
                            </li>
                            <li>
                              <span>Premium Support 24/7 </span>
                            </li>
                          </ul>
                        </div>
                        <div className="table-footer">
                          <a href="/" className="theme-btn btn-style-three">
                            View Profile
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="pricing-table col-lg-4 col-md-6 col-sm-12">
                      <div className="inner-box">
                        <div className="title">Extended</div>
                        <div className="price">
                          $799 <span className="duration">/ monthly</span>
                        </div>
                        <div className="table-content">
                          <ul>
                            <li>
                              <span>1 service posting</span>
                            </li>
                            <li>
                              <span>0 featured service</span>
                            </li>
                            <li>
                              <span>service displayed for 20 days</span>
                            </li>
                            <li>
                              <span>Premium Support 24/7 </span>
                            </li>
                          </ul>
                        </div>
                        <div className="table-footer">
                          <a href="/" className="theme-btn btn-style-three">
                            View Profile
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* End Pricing Section */}
      {/*Start features */}
      <section
        id="features"
        className=""
        style={{ backgroundImage: "url(images/background/9.png)" }}
      >
        <div className="container text-center features-section">
          <div className="row text-left">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
              {" "}
              <img
                src="images/index-12/images/3.png"
                alt="Meet the Hire Pros"
              />{" "}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <h2>Meet the Yourdoorstep</h2>
              <div className="icon_box_one">
                {" "}
                <i
                  className="fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                ></i>
                <div className="box_content">
                  <h4>Yourdoor Step have Team of Expert of there field</h4>
                  <p>
                    Yourdoorstep tranined manpower to serve you at your doorstep
                    since 2011
                  </p>
                </div>
              </div>
              <div className="icon_box_one">
                {" "}
                <i
                  className="fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                ></i>
                <div className="box_content">
                  <h4>No payment, If not Satisfied at YourDoorstep</h4>
                  <p>
                    No payment, If not Satisfied with service
                    <br /> Yourdoorstep Not charge single peny to client if he
                    not staisfed with service as concultancy.
                  </p>
                </div>
              </div>
              <div className="icon_box_one">
                {" "}
                <i
                  className="fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                ></i>
                <div className="box_content">
                  <h4>On time and Quality work promise</h4>
                  <p>
                    Yourdoorstep promise all our client to serve quality and on
                    time work .
                  </p>
                </div>
              </div>
              <div className="icon_box_one">
                {" "}
                <i
                  className="fa fa-arrow-circle-o-right"
                  aria-hidden="true"
                ></i>
                <div className="box_content">
                  <h4>Just book service with Your Doorstep and seat back</h4>
                  <p>
                    Once you book service with Yourdoorstep then you need not to
                    worry at all. Expets will do you work
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End features */}
      {/* Start News Section */}
      <section className="news-section style-two section_padding_top section_padding_bottom">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>Latest Blogs</h2>
            <div className="text">
              Services Related News Content Posted Each Day.
            </div>
          </div>

          <div className="row wow fadeInUp">
            {Blogs.map((value, index) => (
              <BlogPageBox
                Loading={Loading}
                key={index}
                slug={value.url}
                blog_type={value.blog_type}
                lang={value.language}
                image={value.product_image}
                image_title={"title image"}
                image_alt={"alt image"}
                date={value.date}
                title={value.heading}
                tagline={value.desc}
                blogCategory={value.category}
              />
            ))}
          </div>
        </div>
      </section>
      {/* End News Section  */}
    </>
  );
}
