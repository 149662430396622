import { useEffect, useState } from "react";
import axios from "axios";

const RendomTokenData = () => {
  const [rendomToken, setrendomToken] = useState(null);
  useEffect(() => {
    const FetchRendomToken = async () => {
      let fdata = new FormData();
      fdata.append("Status", "1");
      try {
        await axios
          .post(
            `https://yds.hyperofficial.in/api/Webapi/rendomTokenfetch`,
            fdata
          )
          .then((response) => {
            setrendomToken(response.data.token);
          });
      } catch (err) {
        alert(err.message);
      }
    };
    FetchRendomToken();
  }, []);

  return rendomToken;
};

export default RendomTokenData;
