import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Box } from "@mui/material";

const FormStepTwo = ({ values, onNext, onBack }) => {
  const formik = useFormik({
    initialValues: {
      firstName: values.firstName || "",
      lastName: values.lastName || "",
      email: values.email || "",
      address: values.address || "",
      city: values.city || "",
      state: values.state || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      address: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
    }),
    onSubmit: (formValues) => {
      onNext(formValues);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
           <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <div className="row">
          <div className="col-lg-4 col-md-6-col-sm-12 mb-3">
            {" "}
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </div>
          <div className="col-lg-4 col-md-6-col-sm-12 mb-3">
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </div>
          <div className="col-lg-4 col-md-6-col-sm-12 mb-3">
            {" "}
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
          <div className="col-lg-4 col-md-6-col-sm-12 mb-3">
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </div>
          <div className="col-lg-4 col-md-6-col-sm-12 mb-3">
            {" "}
            <TextField
              fullWidth
              label="City"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </div>
          <div className="col-lg-4 col-md-6-col-sm-12 mb-3">
            <TextField
              fullWidth
              label="State"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
            />
          </div>
        </div>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
           <button className="AllsiteButtonbgWhite mt-4" onClick={onBack} type="submit" name="submit">
           BACK
        </button>
           <button className="AllsiteButton mt-4" type="submit" name="submit">
          NEXT
        </button>
        </Box>
    </form>
  );
};

export default FormStepTwo;
