import React from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import WebsiteSetting, { ServicesDetails } from "../FetchAPI";
// import PageBanner from "../PageBanner";
// import ServicePlanBox from "./ServicePlanBox";
import WhyChoose from "../WhyChoose/WhyChoose";
import PageBradcrume from "../PageBradcrume";

export default function ServiceDetails({ RendomTokenfetch }) {
  let { serviceSlug } = useParams();
  // const [webSetting] = WebsiteSetting();
  const [ServiceDetails] = ServicesDetails(RendomTokenfetch, serviceSlug);
  // console.log(ServiceDetails);
  const [webSetting] = WebsiteSetting(RendomTokenfetch);

  return (
    <>
      <Helmet>
        <title>{ServiceDetails.meta_title}</title>
        <meta name="description" content={ServiceDetails.meta_description} />
        <meta name="keywords" content={ServiceDetails.meta_keyword} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {/* <PageBanner
        image={ServiceDetails.banner_image}
        image_alt={ServiceDetails.banner_alt_tag}
        image_title={ServiceDetails.banner_title_tag}
      /> */}
      <PageBradcrume
        Pagename={ServiceDetails.servicetitle}
        pagetitle1={ServiceDetails.category}
        pagetitle2={ServiceDetails.servicetitle}
      />
      <section id="serviceDetails" className="candidate-detail-section">
        <div className="candidate-detail-outer">
          <div className="auto-container">
            <div className="row">
              <div className="content-column col-lg-8 col-md-12 col-sm-12">
                <div className="job-detail">
                  <span className="servisepost-categories">
                    {ServiceDetails.category}
                  </span>
                  <h1 className="mb-3 heading-bold">
                    {ServiceDetails.servicetitle}
                  </h1>
                  <div
                    className="post-content entry-content"
                    dangerouslySetInnerHTML={{
                      __html: ServiceDetails.description,
                    }}
                  ></div>
                </div>
              </div>

              <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar">
                  <div className="video-outer">
                    <div className="video-box">
                      {/* <figure className="image">
                        <Link
                          to={ServiceDetails.video_link}
                          className="play-now"
                          data-fancybox="gallery"
                          data-caption=""
                        >
                          <img src="../images/servicedetails.jpg" alt="" />
                          <i
                            className="icon flaticon-play-button-3"
                            aria-hidden="true"
                          ></i>
                          <div className="Imageoverlay"></div>
                        </Link>
                      </figure> */}
                      <div className="post-thumbnail">
                        {" "}
                        <iframe
                          width="100%"
                          height="100%"
                          src="https://www.youtube.com/embed/wBQauoc1bj0"
                          title={ServiceDetails.servicetitle}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen=""
                        ></iframe>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar-widget social-media-widget">
                    <h4 className="widget-title">Social media</h4>
                    <div className="widget-content">
                      <div className="social-links">
                        <Link to={webSetting.facebook}>
                          <i className="fa fa-facebook-f"></i>
                        </Link>
                        <Link to={webSetting.youtube}>
                          <i className="fa fa-youtube"></i>
                        </Link>
                        <Link to={webSetting.twitter}>
                          <i className="fa fa-twitter"></i>
                        </Link>
                        <Link to={webSetting.instagram}>
                          <i className="fa fa-instagram"></i>
                        </Link>
                        <Link to={webSetting.linkedin}>
                          <i className="fa fa-linkedin-in"></i>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="sidebar-widget">
                    <h4 className="mb-3">Information Form</h4>
                    <iframe
                      width="100%"
                      height="480"
                      src="https://manage.yourdoorstep.co/forms/ticket"
                      frameBorder="0"
                      allowFullScreen
                      title="form"
                    ></iframe>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Sectioin */}
      <section className="pricing-section section_padding_bottom section_padding_top bgc-home20">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>Pricing Packages</h2>
            {/* <div className="text">
              Lorem ipsum dolor sit amet elit, sed do eiusmod tempor.
            </div> */}
          </div>
          <div className="row">
            {/* {detailsServicePlan &&
              detailsServicePlan.map((value, index) => (
                <ServicePlanBox
                  key={index}
                  planid={value.id}
                  title={value.title}
                  selling_price={value.selling_price}
                  price={value.price}
                  plan_type={value.plan_type}
                  service_tag={value.service_tag}
                />
              ))} */}
          </div>
        </div>
      </section>
      {/* End Pricing Section */}

      {/* Why Chhose us start */}
      <WhyChoose />
      {/* Why Chhose us End */}
    </>
  );
}
