import React from "react";
import ServiceBox from "./ServiceBox";
import { AllCatserviceSection } from "../FetchAPI";
import { NavLink, useLocation } from "react-router-dom";

export default function ServiceSection({
  RendomTokenfetch,
  catid,
  servicelimit,
  limittype,
  bgColor,
}) {
  const location = useLocation();
  const [serviceData, catdata] = AllCatserviceSection(
    RendomTokenfetch,
    catid,
    servicelimit,
    limittype
  );

  // console.log(catdata);

  function ShowviewMore(sercatslug) {
    let showView = "";
    if (location.pathname === "/services") {
      showView = "";
    } else {
      showView = (
        <div className="category-block-two at-home20 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
          <NavLink to={`/service/${sercatslug}`}>
            <div className="inner-box">
              <div className="content serviceContent">
                {/* <span className="icon flaticon-money-1"></span> */}
                <div className="imageiconBox">
                  <img src="images/more.png" alt="" className="serviceIcon" />
                </div>
                <h4>View More</h4>
              </div>
            </div>
          </NavLink>
        </div>
      );
    }
    return showView;
  }

  return (
    <>
      {serviceData && (
        <section
          className={`job-categories section_padding_top section_padding_bottom ${bgColor}`}
        >
          <div className="auto-container">
            <div className="sec-title text-center">
              <h2>{catdata.catname && catdata.catname}</h2>
            </div>
            <div className="row wow fadeInUp">
              {serviceData &&
                serviceData.map((value, index) => (
                  <ServiceBox
                    key={index}
                    title={value.title}
                    slug_url={value.service_slug}
                    icon_image={value.image}
                  />
                ))}
              {ShowviewMore(catdata.catslug)}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
