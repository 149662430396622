import React from "react";
import { Helmet } from "react-helmet";
import WebsiteSetting, { AllPagesDatas } from "./FetchAPI";

export default function HomeMetaData({RendomTokenfetch}) {
  const [webSetting] = WebsiteSetting(RendomTokenfetch);
  const [AllpageData] = AllPagesDatas(1);
  // console.log(AllpageData.metatitle);
  return (
    <>
      <Helmet>
        <title>{AllpageData.metatitle}</title>
        <meta name="description" content={AllpageData.metadesc1} />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="keywords" content={AllpageData.metakeyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index, follow"></meta>
        <meta property="og:url" content={AllpageData.base_url} />
        <meta property="og:site_name" content={AllpageData.base_url} />
        <meta property="og:title" content={AllpageData.metatitle} />
        <meta property="og:description" content={AllpageData.metadesc1} />
        <link rel="icon" type="image/x-icon" href={webSetting.fevicon} />
      </Helmet>
    </>
  );
}

