import React from "react";
import { useParams } from "react-router-dom";
import PageMetaData from "../PageMetaData";
import PageBradcrume from "../PageBradcrume";
import { ServiceCatWise,SingleCatName } from "../FetchAPI";
import ServiceBox from "./ServiceBox";
// import PageBanner2 from "../PageBanner2";

export default function Service({ RendomTokenfetch }) {
  let { category } = useParams();
  const [ServiceCat] = ServiceCatWise(RendomTokenfetch, category);
  const [CatNamesigle] = SingleCatName(RendomTokenfetch, category);
  // console.log(CatNamesigle);
  return (
    <>
      <PageMetaData PageId="4" />
      <PageBradcrume Pagename={CatNamesigle.catname} pagetitle1={CatNamesigle.catname} />

      <section
        className={`job-categories section_padding_top section_padding_bottom`}
      >
        <div className="auto-container">
          {/* <div className="sec-title text-center">
          <h2>{ServiceCat.category}</h2>
          </div> */}
          <div className="row wow fadeInUp">
            {ServiceCat &&
              ServiceCat.map((value, index) => (
                <ServiceBox
                  key={index}
                  title={value.title}
                  slug_url={value.service_slug}
                  icon_image={value.image}
                />
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
