import React from "react";
import { NavLink } from "react-router-dom";

export default function BlogPageBox({
  Loading,
  slug,
  blog_type,
  lang,
  image,
  image_title,
  image_alt,
  date,
  title,
  tagline,
  blogCategory,
}) {
  if (Loading) {
    return <h2>Loding...</h2>
  }

  return (
    <>
    
      <div className="news-block col-lg-4 col-md-4 col-sm-6">
        <div className="inner-box">
          <div className="image-box">
            <figure className="image">
              <NavLink to={blog_type === "0" ? `/${slug}` : `/${lang}/${slug}`}>
                <img src={image} alt={image_alt} title={image_title} />
              </NavLink>
            </figure>
          </div>
          <div className="lower-content">
            <ul className="post-meta">
              <li>
                <span>{blogCategory && blogCategory}</span>
              </li>
              <li>
                <span>
                  {date && 
                    <i className="fa fa-clock m-1" aria-hidden="true"></i>
                  }
                 {date}
                </span>
              </li>
            </ul>
            <h3>
              <NavLink to={blog_type === "0" ? `/${slug}` : `/${lang}/${slug}`}>
                {title}
              </NavLink>
            </h3>
            <p className="text">{tagline}</p>
            <NavLink
              to={blog_type === "0" ? `/${slug}` : `/${lang}/${slug}`}
              className="read-more"
            >
              Read More <i className="fa fa-angle-right"></i>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
