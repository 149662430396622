import React from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import DeshBoardFooter from "./DeshBoardFooter";
// import "react-responsive-modal/styles.css";
// import { Modal } from "react-responsive-modal";

export default function VendorPermission({
  ShowUserSideBar,
  RendomTokenfetch,
}) {
  //   const [open, setOpen] = useState(false);

  //   const onOpenModal = () => setOpen(true);
  //   const onCloseModal = () => setOpen(false);
  return (
    <>
      <div className="page-wrapper dashboard mm-page mm-slideout" id="mm-0">
        <Sidebar ShowUserSideBar={ShowUserSideBar} />

        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <Link to="/vendor-request" className="ui-item">
                  <div className="left">
                    <i className="icon la la-bookmark-o"></i>
                  </div>
                  <div className="right">
                    <h6>User Permission</h6>
                    {/* <p>User Permission</p> */}
                  </div>
                </Link>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <Link to="/vendor-request" className="ui-item ui-red">
                  <div className="left">
                    <i className="icon la la-comment-o"></i>
                  </div>
                  <div className="right">
                    <h6>Vendor Permission</h6>
                    {/* <p>Job Alerts</p> */}
                  </div>
                </Link>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="ui-item ui-yellow">
                  <div className="left">
                    <i className="icon la la-file-invoice"></i>
                  </div>
                  <div className="right">
                    <h6>Agent Vendor</h6>
                    {/* <p>Messages</p> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="ui-item ui-green">
                  <div className="left">
                    <i className="icon flaticon-briefcase"></i>
                  </div>
                  <div className="right">
                    <h6>Given Work Vendor</h6>
                    {/* <p>Shortlist</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="deshboard_footer">
          <DeshBoardFooter RendomTokenfetch={RendomTokenfetch} />
        </div>
      </div>

      {/* <Modal open={open} onClose={onCloseModal} center>
        <div className="row">
          <div className="col-lg-12">
            <div className="ls-widget">
              <div className="tabs-box">
                <div className="widget-title">
                  <h3>Verify Document</h3>
                </div>

                <div className="widget-content">
                  <form className="default-form">
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-12">
                        <label>Company name (optional)</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Invisionn"
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-12">
                        <label>Email address</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="creativelayers"
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-12">
                        <label>Phone</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="0 123 456 7890"
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-12">
                        <label>Website</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="www.invision.com"
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-12">
                        <label>Est. Since</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="06.04.2020"
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-12">
                        <label>Allow In Search & Listing</label>
                        <select className="chosen-select">
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>

                      <div className="form-group col-lg-12 col-md-12">
                        <label>About Company</label>
                        <textarea placeholder="Spent several years working on sheep on Wall Street. Had moderate success investing in Yugo's on Wall Street. Managed a small team buying and selling Pogo sticks for farmers. Spent several years licensing licorice in West Palm Beach, FL. Dev"></textarea>
                      </div>
                    </div>
                    <div className="uploading-outer">
                      <div className="uploadButton">
                        <input
                          className="uploadButton-input"
                          type="file"
                          name="attachments[]"
                          accept="image/*, application/pdf"
                          id="upload"
                          multiple
                        />
                        <label
                          className="uploadButton-button ripple-effect"
                          htmlFor="upload"
                        >
                          Browse Logo
                        </label>
                        <span className="uploadButton-file-name"></span>
                      </div>
                      <div className="text">
                        Max file size is 1MB, Minimum dimension: 330x300 And
                        Suitable files are .jpg & .png
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 text-center">
                      <button className="theme-btn btn-style-three submitbtn">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
}
