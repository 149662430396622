import React from "react";
import { NavLink } from "react-router-dom";

export default function PageBradcrume({ Pagename, pagetitle1, pagetitle2 }) {
  return (
    <>
      {/* PageBradcrume Start  */}
      <section className="page-title">
        <div className="auto-container">
          <div className="title-outer">
            <h1>{Pagename}</h1>
            <ul className="page-breadcrumb">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>{pagetitle1}</li>
              {pagetitle2 && <li>{pagetitle2}</li>}
            </ul>
          </div>
        </div>
      </section>
      {/* PageBradcrume  End */}
    </>
  );
}
