

function LoginAPIvalidation(Values) {
    let error = {};
    if (Values.number) {
        error.number = Values.number;
      } else {
        error.number = "";
      }

    if (Values.password) {
        error.password = Values.password;
      } else {
        error.password = "";
      }

  return error;
}

export default LoginAPIvalidation