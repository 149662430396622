
function ProfileEditValidation(Values) {
    let error = {};
    if (Values.firstname) {
        error.firstname = Values.firstname;
      } else {
        error.firstname = "";
      }
      
    if (Values.lastname) {
        error.lastname = Values.lastname;
      } else {
        error.lastname = "";
      }
      
    if (Values.email) {
        error.email = Values.email;
      } else {
        error.email = "";
      }

    if (Values.number) {
        error.number = Values.number;
      } else {
        error.number = "";
      }
  return error;
}

export default ProfileEditValidation