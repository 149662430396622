import React from "react";
import PageMetaData from "../PageMetaData";
import PageBanner2 from "../PageBanner2";
import ServiceSection from "./ServiceSection";
import { Homesetting } from "../FetchAPI";

export default function Services({ RendomTokenfetch }) {
  const [SettingHome] = Homesetting(RendomTokenfetch);
  // console.log(location.pathname);
  return (
    <>
      <PageMetaData PageId="4" />
      <PageBanner2 PageId="4" />
      {/* <PageBradcrume Pagename="Services" pagetitle="services" /> */}
      {/* Services Categories 1 */}
      <ServiceSection
        RendomTokenfetch={RendomTokenfetch}
        catid={SettingHome.category_section1}
        servicelimit={SettingHome.category_section_limit1}
        limittype={"0"}
        bgColor={"bgc-home20"}
      />
      {/* Services End 1*/}

      {/* Services Categories 2 */}
      <ServiceSection
        RendomTokenfetch={RendomTokenfetch}
        catid={SettingHome.category_section2}
        servicelimit={SettingHome.category_section_limit2}
        limittype={"0"}
      />
      {/* Services End 2*/}

      {/* Services Categories 3 */}
      <ServiceSection
        RendomTokenfetch={RendomTokenfetch}
        catid={SettingHome.category_section3}
        servicelimit={SettingHome.category_section_limit3}
        limittype={"0"}
      />
      {/* Services End 3*/}
    </>
  );
}
