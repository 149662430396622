import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const Auth = localStorage.getItem("userid");


  useEffect(() => {
    if (Auth === null) {
      navigate("/"); // Redirect to login or home page
    }
  }, [Auth, navigate]);

  return Auth !== null ? children : null; // Render children only if authenticated
};

// const ProtectedLoginSign = ({ children }) => {
//   const navigate = useNavigate();
//   const Auth = localStorage.getItem("userid");


//   useEffect(() => {
//     if (Auth === null) {
//       navigate("/"); // Redirect to login or home page
//     }
//   }, [Auth, navigate]);

//   return Auth === null ? children : null; // Render children only if authenticated
// };

export default ProtectedRoute;
// export {ProtectedLoginSign};