import React from "react";
import Sidebar from "./Sidebar";
import { UserProfileData } from "../FetchAPI";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import DeshBoardFooter from "./DeshBoardFooter";

export default function Profile({ ShowUserSideBar, RendomTokenfetch }) {
  const [ProfileuserData, loading] = UserProfileData(RendomTokenfetch);

  return (
    <>
      <div className="page-wrapper dashboard mm-page mm-slideout" id="mm-0">
        <Sidebar ShowUserSideBar={ShowUserSideBar} />
        {loading ? (
          <p className="text-center">Loading...</p>
        ) : (
          <>
            <section className=" user-dashboard company_detail">
              <div className="dashboard-outer">
                <div className="company_detail_image">
                  {ProfileuserData.banner !== "" ? (
                    <img
                      src={`${ProfileuserData.base_url}${ProfileuserData.banner}`}
                      alt="avatar"
                      className="thumb h-350"
                    />
                  ) : (
                    <img
                      src="images/bannerUser.jpg"
                      alt="avatar"
                      className="thumb h-350"
                    />
                  )}
                </div>

                <div className="container-fluid px-0">
                  <div className="d-flex justify-content-between  flex-wrap">
                    <div className="company_detail_content">
                      <div className="company_detail_image_2">
                        {ProfileuserData.image !== "" ? (
                          <img
                            src={`${ProfileuserData.base_url}${ProfileuserData.image}`}
                            alt="avatar"
                            className="thumb"
                          />
                        ) : (
                          <img
                            src="images/blankUser.png"
                            alt="avatar"
                            className="thumb"
                          />
                        )}
                      </div>
                      <div className="company_detail_content_in">
                        <h4>
                          {ProfileuserData.username && ProfileuserData.username}
                        </h4>
                        <p>
                          <i
                            className="fa fa-envelope icon me-2"
                            aria-hidden="true"
                          ></i>{" "}
                          {ProfileuserData.email && ProfileuserData.email}
                        </p>
                      </div>
                    </div>

                    <div className="">
                      <Link to="/profile-edit" className="AllsiteButton mt-4 ">
                        <i className="fa fa-pen-to-square"></i> Edit{" "}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="mt-4 d-block">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="intro">
                        <div className="intro1">
                          <h3>Intro</h3>
                        </div>
                        <ul>
                          {ProfileuserData.address && (
                            <li className="mb-3">
                              <i className="fa fa-location"></i>
                              {ProfileuserData.address}
                            </li>
                          )}
                          {ProfileuserData.email && (
                            <li className="mb-3">
                              <i
                                className="fa fa-envelope"
                                aria-hidden="true"
                              ></i>
                              {ProfileuserData.email}
                            </li>
                          )}
                          {ProfileuserData.number && (
                            <li className="mb-3">
                              <i className="fa fa-phone"></i>
                              {ProfileuserData.number}
                            </li>
                          )}
                          {ProfileuserData.facebook_link && (
                            <li className="mb-3">
                              <i className="fa fa-facebook"></i>{" "}
                              {ProfileuserData.facebook_link}
                            </li>
                          )}
                          {ProfileuserData.instagram_link && (
                            <li className="mb-3">
                              <i className="fa fa-instagram"></i>
                              {ProfileuserData.instagram_link}
                            </li>
                          )}
                          {ProfileuserData.linkedin_link && (
                            <li className="mb-3">
                              <i className="fa fa-linkedin"></i>
                              {ProfileuserData.linkedin_link}
                            </li>
                          )}
                          {ProfileuserData.twitter_link && (
                            <li className="mb-3">
                              <i className="fa fa-twitter"></i>
                              {ProfileuserData.twitter_link}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="intro">
                        <div className=" orderleadbox ">
                          <h3 className="mb-0">My Services</h3>
                          <Tooltip
                            title="Convert My Number Order"
                            placement="top"
                            arrow
                          >
                            <Link className="AllsiteButton">Convert Order</Link>
                          </Tooltip>
                        </div>
                        <div className="orderleadtable">
                          <table className="default-table manage-job-table">
                            <thead>
                              <tr>
                                <th>Ticket ID</th>
                                <th>Name</th>
                                <th>Service</th>
                                <th>Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>49865</td>
                                <td>Name Change</td>
                                <td>
                                  <span className="badge bg-primary">
                                    In Progress
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td>2</td>
                                <td>56489965</td>
                                <td>Marriage Certificate</td>
                                <td>
                                  <span className="badge bg-success">
                                    Not Started
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>5345</td>
                                <td>Court Marriage</td>
                                <td>
                                  <span className="badge bg-danger">
                                    Complete
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}

        <div className="deshboard_footer">
          <DeshBoardFooter RendomTokenfetch={RendomTokenfetch} />
        </div>
      </div>
    </>
  );
}
