import React from "react";
import Sidebar from "./Sidebar";
import { AllserviceLeads } from "../FetchAPI";
import "./wallet.css";
import LeadBox from "./LeadBox";
import DeshBoardFooter from "./DeshBoardFooter";

export default function Leads({ ShowUserSideBar, RendomTokenfetch }) {
  const [UserLead, Status, msg] = AllserviceLeads(RendomTokenfetch, "5", "0");
  // console.log(UserLead);

  const ShowNotfoundlead = (UserLeadPera, leadStatus, leadmsg) => {
    let ShowLeaddata;
    if (leadStatus === "0") {
      ShowLeaddata = (
        <>
          <div className="leadNotfountbox">
            <p>{leadmsg}</p>
          </div>
        </>
      );
    } else {
      ShowLeaddata = (
        <>
          <div className="whiteBoxOuter">
            <div className="row">
              {UserLeadPera &&
                UserLeadPera.map((value, index) => (
                  <LeadBox key={index} leaddata={value} />
                ))}
            </div>
          </div>
        </>
      );
    }
    return ShowLeaddata;
  };
  return (
    <>
      <div className="page-wrapper dashboard mm-page mm-slideout" id="mm-0">
        <Sidebar ShowUserSideBar={ShowUserSideBar} />

        <section className="user-dashboard transaction-history">
          <div className="dashboard-outer dashboardouter">
            <div className="walletheading">
              <h4 className="PageTitleWallet">Service Booking</h4>
            </div>
            {ShowNotfoundlead(UserLead, Status, msg)}
          </div>
        </section>
        <div className="deshboard_footer">
          <DeshBoardFooter RendomTokenfetch={RendomTokenfetch} />
        </div>
      </div>
    </>
  );
}
