import React from 'react';

export default function WhyChoose() {
  return (
    <>
      <section className="why_choose  section_padding_top section_padding_bottom">
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2 className="fw-700">Why Choose Us</h2>
            <div className="text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor
            </div>
          </div>

          <div className="row grid-base">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="step-item text-center">
                <div className="image">
                  <img src="../images/index-12/steps/1.png" alt="choose us" />
                </div>
                <h3 className="title">
                  <span className="text-red">01</span> Satisfied
                </h3>
                <p className="text">1000+ Satisfied Clients</p>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="step-item text-center">
                <div className="image">
                  <img src="../images/index-12/steps/2.png" alt="choose us" />
                </div>
                <h3 className="title">
                  <span className="text-red">02</span> Document
                </h3>
                <p className="text">Full Document Support</p>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="step-item text-center">
                <div className="image">
                  <img src="../images/index-12/steps/3.png" alt="choose us" />
                </div>
                <h3 className="title">
                  <span className="text-red">03</span> Lawyers
                </h3>
                <p className="text">Export Lawyers</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    
    </>
  )
}
