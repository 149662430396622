import React from "react";

export default function CommentBox({ value }) {
  return (
    <>
      {value && (
        <>
          <div className="leadComemntrow">
            <div className="userimagebox">
              <div className="userimgnamebox">
                <span className="thumb mx-0">
                  {value.userimage ? (
                    value.userimage
                  ) : (
                    <img src="../images/resource/candidate-1.png" alt="" />
                  )}
                </span>
                <div className="usernamebox">
                  <h6>{value.username}</h6>
                  <p>{value.useremail}</p>
                </div>
              </div>
              <div className="commentDatatime">
                <p className="whycomment px-1">{value.role} </p>
                <p className="CommnetDate"> {value.commentdate}</p>
              </div>
            </div>
            <div className="commentdecs">
              <p
                dangerouslySetInnerHTML={{
                  __html: value.comment,
                }}
              ></p>
            </div>
          </div>
        </>
      )}
    </>
  );
}
