import React from 'react';
import TermsPageBox from './TermsPageBox';
import PageBradcrume from '../PageBradcrume';
import {AllPagesDatas} from "../FetchAPI";
import PageMetaData from '../PageMetaData';

export default function ProductServiceDescription() {
  const [AllpageData] = AllPagesDatas(8);
  return (
    <>
   <PageMetaData PageId="8"/>
    <PageBradcrume Pagename={AllpageData.title} pagetitle="product-service-description" />
    <TermsPageBox heading={AllpageData.title} desc={AllpageData.description1}/>
    </>
  )
}