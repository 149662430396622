import React from 'react';
import TermsPageBox from './TermsPageBox';
import PageBradcrume from '../PageBradcrume';
import {AllPagesDatas} from "../FetchAPI";
import PageMetaData from '../PageMetaData';

export default function PrivacyPolicy() {
    const [AllpageData] = AllPagesDatas(7);
  return (
    <>
    <PageMetaData PageId="7"/>
     <PageBradcrume Pagename={AllpageData.title} pagetitle="privacy-policy" />
     <TermsPageBox heading={AllpageData.title} desc={AllpageData.description1}/>
     </>
  )
}
