import React from "react";
import { AllPagesDatas } from "./FetchAPI";

export default function PageBanner2({ PageId }) {
  const [AllpageData] = AllPagesDatas(PageId);

  const showBanner = () => {
    let AddData = "";
    if (AllpageData.bannertype === "0") {
      AddData = (
        <div className="blog_iframe">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${AllpageData.bannerimg}`}
            title="Yourdoorstep"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen=""
          ></iframe>
        </div> 
      );
    } else {
      AddData = (
        <img
          src={AllpageData.bannerimg}
          alt={AllpageData.image_alt}
          title={AllpageData.image_title}
          className="bannerimg"
        />
      );
    }
    return AddData;
  };
  return (
    <>
      <section className="PageBanner_section">{showBanner()}</section>
    </>
  );
}
