import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./LeadCommentModelCss.css";
import CommentBox from "./CommentBox";

export default function LeadCommentModel({ leadComment, open, handleClose }) {
  // console.log(leadComment);
  let activeStepsCount = 0;
  if (leadComment) {
    activeStepsCount = leadComment.filter((item) => item).length;
  }
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box className="CommentBoxModel">
          <div className="Commentheading">
            <h4>Comments</h4>
            <button className="CloseModelbtn" onClick={handleClose}>
              Close
            </button>
          </div>
          <div className="commentboxdiv">
            {leadComment &&
              leadComment.map((value, index) => (
                <CommentBox key={index} value={value} />
              ))}
            {activeStepsCount === 0 ? (
              <div className="noCommnetLead">No Comments!</div>
            ) : (
              ""
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
