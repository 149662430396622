function EnqueryValidation(values) {
    let error = {};
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (values.fname === "") {
      error.fname = "Name Should not be Empty!";
    } else {
      error.fname = "";
    }
    
    if (values.email === "") {
      error.email = "Email Should not be Empty!";
    } else if (!email_pattern.test(values.email)) {
      error.email = "Email Did'n Match";
    } else {
      error.email = "";
    } 
  
    if (values.message === "") {
      error.message = "Message Should not be Empty!";
    } else {
      error.message = "";
    }
  
    if (values.number === "") {
      error.number = "Number Should not be Empty!";
    } else {
      error.number = "";
    }
  
    return error;
  }
  
  export default EnqueryValidation;
  