import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../node_modules/react-notifications-component/dist/theme.css";
import "../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
