import React from "react";

export default function LeadStatusStepperRow({
  statusName,
  completestatus,
  cruntstatus,
  completedate,
  stapeCountID,
  StepperLength,
}) {
  
  return (
    <>
      <div className="stepperSatusRow">
        <div className="steppernameBox">
          {completestatus === 1 ? (
            <span className="stepcount stepperComplated">
              <i className="fa fa-check" aria-hidden="true"></i>
            </span>
          ) : cruntstatus === 1 ? (
            <span className="stepcount stepperActive">{stapeCountID + 1}</span>
          ) : (
            <span className="stepcount">{stapeCountID + 1}</span>
          )}

          <span className="steppername">{statusName}</span>
          {stapeCountID === StepperLength - 1 && (
            <span className="Laststepper"> (Last step)</span>
          )}
          {stapeCountID === StepperLength - 1 ? null : (
            <p className="stepperLine"></p>
          )}
        </div>
        <div className="stepperDateBox">
          {completedate && (
            <span className="stepperDate"> Complated Date: {completedate}</span>
          )}
        </div>
      </div>
    </>
  );
}
