import React from 'react';
import TermsPageBox from './TermsPageBox';
import PageBradcrume from '../PageBradcrume';
import {AllPagesDatas} from "../FetchAPI";
import PageMetaData from '../PageMetaData';

export default function TermCondition() {
  const [AllpageData] = AllPagesDatas(6);
  return (
    <>
   <PageMetaData PageId="6"/>
    <PageBradcrume Pagename={AllpageData.title} pagetitle="term-and-condition" />
    <TermsPageBox heading={AllpageData.title} desc={AllpageData.description1}/>
    </>
  )
}