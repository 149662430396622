import React from "react";
import { Typography, Box } from "@mui/material";

const FormStepThree = ({ values, onBack, onSubmit }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h6">Review Your Information</Typography>
      <Typography>
        Name: {values.firstName} {values.lastName}
      </Typography>
      <Typography>Email: {values.email}</Typography>
      <Typography>
        Address: {values.address}, {values.city}, {values.state}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <button className="AllsiteButtonbgWhite mt-4" onClick={onBack} type="submit" name="submit">
           BACK
        </button>
           <button onClick={onSubmit} className="AllsiteButton mt-4" type="submit" name="submit">
          SUBMIT
        </button>
      </Box>
    </Box>
  );
};

export default FormStepThree;
