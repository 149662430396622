import React from "react";

import LeadCommentModel from "./LeadCommentModel";
import { Link } from "react-router-dom";

function LeadCommentBtn({leadComment}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Link to="" className="DefaultHoverbtn mx-2" onClick={handleOpen}>
        <i className="fa fa-commenting-o" aria-hidden="true"></i> Comment
      </Link>
      <LeadCommentModel leadComment={leadComment} open={open} handleClose={handleClose} />
    </>
  );
}

export default LeadCommentBtn;
