import React from "react";
import { HomeServicesPlans } from "./FetchAPI";
import ServicePlanBox from "./Service/ServicePlanBox";

export default function HomePlansServiceBox({ IDService }) {
   
    const [HomePlansService] = HomeServicesPlans(IDService);
    // console.log(HomePlansService);
  return (
    <>
      {HomePlansService &&
        HomePlansService.map((value, index) => (
          <ServicePlanBox
            key={index}
            planid={value.id}
            title={value.title}
            selling_price={value.selling_price}
            price={value.price}
            plan_type={value.plan_type}
            service_tag={value.service_tag}
          />
        ))}
    </>
  );
}
