import React from "react";
import Sidebar from "./Sidebar";
import WebsiteSetting, {
  AllLeadsdetails,
  AllLeadsStatusStepper,
} from "../FetchAPI";
import "./wallet.css";
import LeadStatusStepper from "./LeadStatusStepper";
import { Link, useParams } from "react-router-dom";
import LeadCommentBtn from "./LeadCommentBtn";
import DeshBoardFooter from "./DeshBoardFooter";

export default function LeadDetails({ ShowUserSideBar, RendomTokenfetch }) {
  let { leadid } = useParams();
  const [webSetting] = WebsiteSetting(RendomTokenfetch);
  const [LeadDetails, leadComment] = AllLeadsdetails(RendomTokenfetch, leadid);
  const servicID = LeadDetails.service_id;
  const [LeadstatuStepper, StepperLength] = AllLeadsStatusStepper(
    RendomTokenfetch,
    leadid,
    servicID
  );

  // console.log(leadComment);

  return (
    <>
      <div className="page-wrapper dashboard mm-page mm-slideout" id="mm-0">
        <Sidebar ShowUserSideBar={ShowUserSideBar} />

        <section className="user-dashboard transaction-history">
          <div className="dashboard-outer dashboardouter">
            <div className="walletheading">
              <p className="Leaddetailsbraudcrume">
                <Link to="/service-booking" className="serctionBradcrume">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>{" "}
                  Service Booking
                </Link>
                <span> / {LeadDetails.name}</span>
              </p>
            </div>
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 mb-4">
                <div className="card height-equal card_wallet">
                  <div className="leaddetailsdatbox">
                    <div className="leadsrImgBox">
                      <span className="thumb mx-0">
                        {LeadDetails.service_icon !== "" ? (
                          <img src={LeadDetails.service_icon} alt="" />
                        ) : (
                          <img src="images/resource/candidate-1.png" alt="" />
                        )}
                      </span>

                      <div className="ms-sm-2 leadnametacskBox">
                        <h3 className="name">
                          <span>{LeadDetails.name}</span>
                        </h3>
                        <ul className="job-info justify-content-start at-clv7 mt-1">
                          <li className="ms-0 ps-0">
                            Ticket no #{LeadDetails.id}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="CommnetsLeadbox">
                      <LeadCommentBtn leadComment={leadComment} />
                    </div>
                  </div>

                  <div className="SectionBoxintro">
                    <h4>Process</h4>
                  </div>
                  <div className="card-body custom-input">
                    <LeadStatusStepper
                      LeadstatuStepper={LeadstatuStepper}
                      LengthStepper={StepperLength}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6  mb-12">
                <div className="card height-equal card_wallet">
                  <div className="SectionBoxintro">
                    <h4>Need more help?</h4>
                  </div>
                  <div className="card-body custom-input pt-0">
                    <div className="leadsrImgBox needMoreBox">
                      <span className="thumb mx-0">
                        <img src="../images/resource/candidate-1.png" alt="" />
                      </span>
                      <div className="textbos ms-0 ms-sm-2">
                        <p className="mb-0 textbos">Contact expert</p>
                        <h3 className="name">
                          <a href="/">yourdoorstep</a>
                        </h3>
                      </div>
                    </div>
                    <div className="callWhatsappBTN">
                      <Link
                        to={`tel:${webSetting.mobile_number}`}
                        className="CallBoxBtn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-phone" aria-hidden="true"></i> Call
                      </Link>
                      <Link
                        to={`https://wa.me/${webSetting.whatsapp}?text=Hi, I would like to speak to a Name Change Service`}
                        target="_blank"
                        rel="noreferrer"
                        className="whatsappBoxBtn"
                      >
                        <i className="fa fa-whatsapp" aria-hidden="true"></i>{" "}
                        Whatsapp
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="deshboard_footer">
        <DeshBoardFooter RendomTokenfetch={RendomTokenfetch} />
      </div>
      </div>

      
    </>
  );
}
