import React, { useState } from "react";
// import PageBanner from "../PageBanner";
// import PageBradcrume from "../PageBradcrume";
// import { NavLink } from "react-router-dom";
import BlogPageBox from "./BlogPageBox";
// import { useParams } from "react-router-dom";
import { BlogPageBlog } from "../FetchAPI";
import { Link } from "react-router-dom";
import PageMetaData from "../PageMetaData";
import PageBanner2 from "../PageBanner2";
// import Paginat from "./Paginat";

export default function Blog() {
  const [Blogs, Loading] = BlogPageBlog();
  const [CurrentPage, setCurrentPage] = useState(1);
  const [PostperPage] = useState(12);

  //Get Current Posts
  const indexofLastPost = CurrentPage * PostperPage;
  const indexofFirstPost = indexofLastPost - PostperPage;
  const CurrentPost = Blogs.slice(indexofFirstPost, indexofLastPost);

  const npage = Math.ceil(Blogs.length / PostperPage);
  const Numbers = [...Array(npage + 1).keys()].slice(1);

  // const Pagina =(PageNumber)=>setCurrentPage(PageNumber);

  function prePage() {
    if (CurrentPage !== 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (CurrentPage !== npage) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  return (
    <>
      <PageMetaData PageId="3" />
      <PageBanner2 PageId="3" />
      {/* <PageBradcrume Pagename="Blog" pagetitle="blog" /> */}
      {/* <PageBanner
        image={"images/banner/blogbanner-1.jpg"}
        image_alt={"Blog"}
        image_title={"Blog"}  
      /> */}

      {/* Sidebar Page Container */}
      <div className="sidebar-page-container">
        <div className="auto-container">
          <div className="row">
            {/* Content Side */}
            <div className="content-side col-lg-12 col-md-12 col-sm-12">
              <div className="blog-grid">
                <div className="row">
                  {/* News Block */}

                  {CurrentPost.map((value, index) => (
                    <BlogPageBox
                      Loading={Loading}
                      key={index}
                      slug={value.url}
                      blog_type={value.blog_type}
                      lang={value.language}
                      image={value.product_image}
                      image_title={"title image"}
                      image_alt={"alt image"}
                      date={value.date}
                      title={value.heading}
                      tagline={value.metadesc}
                      blogCategory={value.category}
                    />
                  ))}

                  {Blogs.length === 12 && (
                    <>
                      <div className="PaginationBox">
                        <nav aria-label="..." className="PaginaNAV">
                          <ul className="pagination PaginaUL">
                            <li className="page-item PaginaLI">
                              <Link className="page-link" onClick={prePage}>
                                Previous
                              </Link>
                            </li>
                            {Numbers.map((n, i) => (
                              <li
                                className={`page-item PaginaLI ${
                                  CurrentPage === n ? "active" : ""
                                }`}
                                key={i}
                              >
                                <Link
                                  className="page-link"
                                  onClick={() => changeCPage(n)}
                                >
                                  {n}
                                </Link>
                              </li>
                            ))}

                            <li className="page-item PaginaLI">
                              <Link className="page-link" onClick={nextPage}>
                                Next
                              </Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </>
                  )}

                  {/* <Paginat PostPERPAGE={PostperPage} TotalPost={Blogs.length} Paginat={Pagina}/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Sidebar Container */}
    </>
  );
}
