import * as React from "react";
import "./LeadStatusStepper.css";
import LeadStatusStepperRow from "./LeadStatusStepperRow";

export default function LeadStatusStepper({ LeadstatuStepper, LengthStepper }) {
  return (
    <div className="StepperBox">
      <div className="leadStatusBox">
        {LeadstatuStepper &&
          LeadstatuStepper.map((value, index) => (
            <LeadStatusStepperRow
              key={index}
              statusName={value.status}
              completestatus={value.completestatus}
              cruntstatus={value.cruntstatus}
              completedate={value.completedate}
              stapeCountID={index}
              StepperLength={LengthStepper}
            />
          ))}
      </div>
    </div>
  );
}
