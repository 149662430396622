const SiteUrl = process.env.REACT_APP_SITEURL;

const ShowImagePath = (folder, image) => {
  let imagePath = "";
  if (image) {
    imagePath = `${SiteUrl}${folder}${image}`;
  } else {
    imagePath = "";
  }
  return imagePath;
};

export default ShowImagePath;
