import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function PageNotFound() {
  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <section className="PageNotFound_section ">
        <img
          src="images/notfound3.png"
          alt="Page  Not Found"
          title="Page  Not Found"
          className="PageNotFound"
        />
      </section>

      <div className="backtohomeBox m-5">
        <Link exact="true" to="/" className="backtohomeBtn">
          Back To Home
        </Link>
      </div>
    </>
  );
}
