import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Login from "./Component/Login-Signup/Login";
import Signup from "./Component/Login-Signup/Signup";
import Home from "./Component/Home";
import Blog from "./Component/Blog/Blog";
// import BlogDetails from "./Component/Blog/BlogDetails";
import ScrollToTop from "./Component/ScrollToTop";
import { ReactNotifications } from "react-notifications-component";
import Footer from "./Component/Main/Footer";
import Services from "./Component/Service/Services";
import ServiceDetails from "./Component/Service/ServiceDetails";
import ContactUS from "./Component/ContactUS";
import About from "./Component/About";
import Header from "./Component/Main/Header";
import Blogs from "./Component/Blog/Blogs";
import Enquery from "./Component/Service/Enquiry";
import RefundPolicy from "./Component/TermsPrivacyPage/RefundPolicy";
import TermCondition from "./Component/TermsPrivacyPage/TermCondition";
import PrivacyPolicy from "./Component/TermsPrivacyPage/PrivacyPolicy";
import ProductServiceDescription from "./Component/TermsPrivacyPage/ProductServiceDescription";
import { Deshbard } from "./Component/Deshboard/Deshbard";
import Profile from "./Component/Deshboard/Profile";
import VendorPermission from "./Component/Deshboard/VendorPermission";
import VendorRequest from "./Component/Deshboard/VendorRequest";
import ProfileEdit from "./Component/Deshboard/ProfileEdit";
import ProtectedRoute from "./ProtectedRoute";
import Service from "./Component/Service/Service";
import RendomTokenData from "./Store/StoreToken";
import PageNotFound from "./Component/PageNotFound";
import Wallet from "./Component/Deshboard/Wallet";
import LeadAdd from "./Component/Deshboard/LeadAdd";
import Leads from "./Component/Deshboard/Leads";
import LeadDetails from "./Component/Deshboard/LeadDetails";
import UserFormSubmission from "./Component/FormStepper/UserFormSubmission";
// import PageWithLoader from "./PageWithLoader";
function App() {
  const [SidebarShow, setSidebarShow] = useState(false);

  const UserSidebarShow = () => {
    SidebarShow === true ? setSidebarShow(false) : setSidebarShow(true);
  };
  const RendomTokenfetch = RendomTokenData();

  return (
    <>
      <Router>
        <ScrollToTop />
        <ReactNotifications />
        <Header
          HandleUserSidebar={UserSidebarShow}
          RendomTokenfetch={RendomTokenfetch}
        />
        <Routes>
          <Route
            exact="true"
            path="/"
            element={<Home RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/blog"
            element={<Blog RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/blogs/:language"
            element={<Blogs RendomTokenfetch={RendomTokenfetch} />}
          />
          {/* <Route
            exact="true"
            path="/:slug"
            element={<BlogDetails RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/:lang/:slug"
            element={<BlogDetails RendomTokenfetch={RendomTokenfetch} />}
          /> */}
          <Route
            exact="true"
            path="/contact"
            element={<ContactUS RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/about"
            element={<About RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/services"
            element={<Services RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/service/:category"
            element={<Service RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/services/:serviceSlug"
            element={<ServiceDetails RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/enquiry/:planid"
            element={<Enquery RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/login"
            element={<Login RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/register"
            element={<Signup RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/term-and-condition"
            element={<TermCondition RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/privacy-policy"
            element={<PrivacyPolicy RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/cancellation-refund-policy"
            element={<RefundPolicy RendomTokenfetch={RendomTokenfetch} />}
          />
          <Route
            exact="true"
            path="/product-service-description"
            element={
              <ProductServiceDescription RendomTokenfetch={RendomTokenfetch} />
            }
          />

          <Route
            exact="true"
            path="/deshboard"
            element={
              <>
                <ProtectedRoute>
                  
                      <Deshbard
                        ShowUserSideBar={SidebarShow}
                        RendomTokenfetch={RendomTokenfetch}
                      />
                    
                </ProtectedRoute>
              </> 
            }
          />
          <Route
            exact="true"
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile
                  ShowUserSideBar={SidebarShow}
                  RendomTokenfetch={RendomTokenfetch}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact="true"
            path="/wallet"
            element={
              <ProtectedRoute>
                <Wallet
                  ShowUserSideBar={SidebarShow}
                  RendomTokenfetch={RendomTokenfetch}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact="true"
            path="/leadAdd"
            element={
              <ProtectedRoute>
                <LeadAdd
                  ShowUserSideBar={SidebarShow}
                  RendomTokenfetch={RendomTokenfetch}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact="true"
            path="/service-booking"
            element={
              <ProtectedRoute>
                <Leads
                  ShowUserSideBar={SidebarShow}
                  RendomTokenfetch={RendomTokenfetch}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact="true"
            path="/service-booking/:leadid"
            element={
              <ProtectedRoute>
                <LeadDetails
                  ShowUserSideBar={SidebarShow}
                  RendomTokenfetch={RendomTokenfetch}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact="true"
            path="/user-submission"
            element={
              <ProtectedRoute>
                <UserFormSubmission
                  ShowUserSideBar={SidebarShow}
                  RendomTokenfetch={RendomTokenfetch}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact="true"
            path="/vendor-permission"
            element={
              <ProtectedRoute>
                <VendorPermission
                  ShowUserSideBar={SidebarShow}
                  RendomTokenfetch={RendomTokenfetch}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact="true"
            path="/vendor-request"
            element={
              <ProtectedRoute>
                <VendorRequest
                  ShowUserSideBar={SidebarShow}
                  RendomTokenfetch={RendomTokenfetch}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact="true"
            path="/profile-edit"
            element={
              <ProtectedRoute>
                <ProfileEdit
                  ShowUserSideBar={SidebarShow}
                  RendomTokenfetch={RendomTokenfetch}
                />
              </ProtectedRoute>
            }
          />
          <Route
            exact="true"
            path="/page-not-found"
            element={<PageNotFound />}
          />
          <Route
            exact="true"
            path="*"
            element={<Navigate to="/page-not-found" replace />}
          />
        </Routes>
        <Footer RendomTokenfetch={RendomTokenfetch} />
      </Router>
    </>
  );
}

export default App;
