import React from "react";
import { NavLink } from "react-router-dom";
import { UserProfileData } from "../FetchAPI";
import "./UserDeshbordCSS.css";
// import { useLocation } from "react-router-dom";
// import UserdeshDropdown from "./UserdeshDropdown";

export default function HeaderProfileMenu({
  Logout,
  HandleUserSidebar,
  RendomTokenfetch,
}) {
  const [ProfileuserData, loading] = UserProfileData(RendomTokenfetch);

  // const location = useLocation();
  // const pathurl = location.pathname;

  // const FunshowUserProfile = () => {
  //   let showUserProfile;
  //   if (
  //     pathurl === "/deshboard" ||
  //     pathurl === "/profile" ||
  //     pathurl === "/vendor-permission" ||
  //     pathurl === "/vendor-request" ||
  //     pathurl === "/profile-edit" ||
  //     pathurl === "/wallet"
  //   ) {
  //     showUserProfile = (
  //       <>
  //         <button
  //           // onClick={HandleUserSidebar}
  //           className="dropdown-toggle"
  //           data-toggle="dropdown"
  //           aria-expanded="false"
  //         >
  //           {ProfileuserData.image !== "" ? (
  //             <img
  //               src={`${ProfileuserData.base_url}${ProfileuserData.image}`}
  //               alt="User Profile"
  //               title="User"
  //               className="thumb"
  //             />
  //           ) : (
  //             <img
  //               src="images/blankUser.png"
  //               alt="User Profile"
  //               title="User"
  //               className="thumb"
  //             />
  //           )}

  //           <span className="name">My Profile</span>
  //         </button>
  //       </>
  //     );
  //   } else {
  //     showUserProfile = <UserdeshDropdown ProfileuserData={ProfileuserData} />;
  //   }

  //   return showUserProfile;
  // };

  return (
    <>
      {/* Dashboard Option */}
      <div className={`dropdown dashboard-option `}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <button
            // onClick={HandleUserSidebar}
            className="dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            {ProfileuserData.image !== "" ? (
              <img
                src={`${ProfileuserData.base_url}${ProfileuserData.image}`}
                alt="User Profile"
                title="User"
                className="thumb"
              />
            ) : (
              <img
                src="../images/blankUser.png"
                alt="User Profile"
                title="User"
                className="thumb"
              />
            )}

            <span className="name">My Profile</span>
          </button>
        )}
        <ul className="dropdown-menu Profile_Ul">
          <li className="userDropdownlink">
            <NavLink to="/deshboard" exact="true">
            <i className="fa fa-home" aria-hidden="true"></i> Dashboard
            </NavLink>
          </li>
          {
            <>
              <div className="userdropdownshow">
                <li className="userDropdownlink">
                  <NavLink to="/profile" exact="true">
                  <i className="fa fa-user" aria-hidden="true"></i>My Profile
                  </NavLink>
                </li>{" "}
                {/* <li className="userDropdownlink">
                  <NavLink to="/wallet" exact="true">
                    <i className="la la-wallet"></i>Wallet
                  </NavLink>
                </li>{" "} */}
                {/* <li className="userDropdownlink">
                  <NavLink to="/leadAdd" exact="true">
                    <i className="la la-file-invoice"></i>Lead Add
                  </NavLink>
                </li> */}
                <li className="userDropdownlink">
                  <NavLink to="/service-booking" exact="true">
                  <i className="fa fa-bookmark" aria-hidden="true"></i>Service Booking
                  </NavLink>
                </li>
                {/* <li className="userDropdownlink">
                  <NavLink to="/vendor-permission" exact="true">
                    <i className="la la-paper-plane"></i>Vendor Permission
                  </NavLink>
                </li> */}
              </div>
            </>
          }

          {/* <li><a href="dashboard-company-profile.html"><i className="la la-user-tie"></i>Company Profile</a></li> */}
          {/* <li><a href="dashboard-post-job.html"><i className="la la-paper-plane"></i>Post a New Job</a></li> */}
          {/* <li><a href="dashboard-manage-job.html"><i className="la la-briefcase"></i> Manage Jobs </a></li> */}
          {/* <li><a href="dashboard-applicants.html"><i className="la la-file-invoice"></i> All Applicants</a></li> */}
          {/* <li><a href="dashboard-resumes.html"><i className="la la-bookmark-o"></i>Shortlisted Resumes</a></li> */}
          {/* <li><a href="dashboard-packages.html"><i className="la la-box"></i>Packages</a></li> */}
          {/* <li><a href="dashboard-messages.html"><i className="la la-comment-o"></i>Messages</a></li> */}
          {/* <li><a href="dashboard-resume-alerts.html"><i className="la la-bell"></i>Resume Alerts</a></li> */}
          {/* <li><a href="dashboard-change-password.html"><i className="la la-lock"></i>Change Password</a></li> */}
          {/* <li><a href="/"><i className="la la-user-alt"></i>View Profile</a></li> */}
          <li>
            <button onClick={Logout}>
            <i className="fa fa-sign-out" aria-hidden="true"></i>Logout
            </button>
          </li>
          {/* <li><a href="index.html"><i className="la la-trash"></i>Delete Profile</a></li> */}
        </ul>
      </div>
      {/* <button id="toggle-user-sidebar" onClick={HandleUserSidebar}>
        {ProfileuserData.image !== "" ? (
          <img
            src={`${ProfileuserData.base_url}${ProfileuserData.image}`}
            alt="User Profile"
            title="User"
            className="thumb"
          />8
        ) : (
          <img
            src="images/blankUser.png"
            alt="User Profile"
            title="User"
            className="thumb"
          />
        )}
      </button> */}
    </>
  );
}
