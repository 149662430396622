import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

function Sidebar({ ShowUserSideBar }) {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    localStorage.removeItem("userid");
    navigate("/login");
  };
  // const [isOpen, setIsopen] = useState(false);
  return (
    <>
      <div
        className={`user-sidebar usersitebarActive ${
          ShowUserSideBar === true ? "UserSidebarActive" : ""
        }`}
      >
        <div className="sidebar-inner">
          <ul className="navigation">
            <li className="sidebarLink">
              <NavLink to="/deshboard" exact="true">
                {" "}
                <i className="fa fa-home" aria-hidden="true"></i> Dashboard
              </NavLink>
            </li>
            <li className="sidebarLink">
              <NavLink to="/profile" exact="true">
              <i className="fa fa-user" aria-hidden="true"></i>My Profile
              </NavLink>
            </li>
            {/* <li className="sidebarLink">
              <NavLink to="/wallet" exact="true">
                <i className="la la-wallet"></i>Wallet
              </NavLink>
            </li> */}
            {/* <li className="sidebarLink">
              <NavLink to="/leadAdd" exact="true">
                <i className="la la-file-invoice"></i>Lead Add
              </NavLink>
            </li> */}
            <li className="sidebarLink">
              <NavLink to="/service-booking" exact="true">
              <i className="fa fa-bookmark" aria-hidden="true"></i>Service Booking
              </NavLink>
            </li>

            {/* <li className="sidebarLink">
              <NavLink to="/vendor-permission" exact="true">
                <i className="la la-paper-plane"></i>Vendor Permission
              </NavLink>
            </li> */}
            {/* <li className="sidebarLink">
              <NavLink to="/user-submission" exact="true">
                <i className="la la-paper-plane"></i>User Submission
              </NavLink>
            </li> */}
            <li className="sidebarLink">
              <NavLink to="/login" exact="true" onClick={logout}>
              <i className="fa fa-sign-out" aria-hidden="true"></i>Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
