import React from "react";

export default function TermsPageBox({ heading, desc }) {
  return (
    <>
      <section className="tnc-section">
        <div className="auto-container">
          {/* <div className="sec-title text-center">
            <h2>{heading}</h2>
          </div> */}
          <div
            className="text-box"
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          ></div>
        </div>
      </section>
    </>
  );
}
