import React, { useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import LoginAPIvalidation from "./LoginAPIvalidation";
import "./Login.css";

export default function Login({ RendomTokenfetch }) {
  const navigate = useNavigate();
  //   const Auth = localStorage.getItem("userid");
  //   if (Auth === null) {
  //   navigate("/");
  // }
  // if (localStorage.getItem("userid") !== "") {
  //   navigate("/");
  // }
  const [error, setError] = useState({});

  const notification = {
    // message: "teodosii@react-notifications-component",
    insert: "top",
    container: "bottom-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 1000,
      showIcon: true,
      onScreen: true,
    },
  };

  const [Data, setData] = useState({
    number: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (e) => {
    setData({ ...Data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let fdata = new FormData();
    fdata.append("number", Data.number);
    fdata.append("password", Data.password);
    // console.log(Data);

    axios
      .post("https://yds.hyperofficial.in/api/Webapi/userlogin", fdata, {
        headers: {
          Authorization: RendomTokenfetch,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (Data.number && Data.password) {
          if (response.data.Status === "1") {
            console.log(response.data);
            Store.addNotification({
              ...notification,
              title: response.data.msg,
              type: "success",
              onRemoval: () => {
                window.location.reload();
              },
            });
            window.localStorage.setItem(
              "userid",
              response.data.userdata.userid
            );
            window.localStorage.setItem(
              "userMultipalData",
              JSON.stringify(response.data.userdata)
            );
            navigate("/");
          }
          if (response.data.Status === "0") {
            Store.addNotification({
              ...notification,
              title: response.data.msg,
              type: "danger",
            });
          }
        } else {
          setError(LoginAPIvalidation(response.data.form_validation));
        }
      });
    // .catch((error) => {
    //   alert(error);
    // });
  };

  return (
    <>
      <section className="login-section section_padding_top bgc-home20">
        <div className="login-form default-form login_page_form">
          <div className="form-inner">
            <h3>Login to WZX</h3>

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>
                  Mobile Number<span className="text-danger">*</span>
                </label>
                <div className="otpganratebox">
                  <input
                    className="form-controll"
                    type="text"
                    name="number"
                    value={Data.number}
                    onChange={handleChange}
                  />
                </div>
                {error.number && (
                  <span className="text-danger">* {error.number}</span>
                )}
              </div>

              <div className="form-group">
                <label>
                  Password<span className="text-danger">*</span>
                </label>
                <input
                  id="password-field"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={Data.password}
                  onChange={handleChange}
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="paaswordshowhide"
                >
                  {showPassword ? (
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                  )}
                </span>

                {error.password && (
                  <span className="text-danger">* {error.password}</span>
                )}
              </div>

              <div className="form-group">
                <button
                  className="theme-btn btn-style-one"
                  type="submit"
                  name="submit"
                >
                  Log In
                </button>
              </div>
            </form>

            <div className="bottom-box text-center d-block">
              <div className="text">
                Don't have an account ? <NavLink to="/register">Signup</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
