import React, { useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import Sidebar from "./Sidebar";
import { UserProfileData } from "../FetchAPI";
import ProfileEditValidation from "./ProfileEditValidation";
import { Link } from "react-router-dom";
import DeshBoardFooter from "./DeshBoardFooter";

export default function ProfileEdit({ ShowUserSideBar, RendomTokenfetch }) {
  const [ProfileuserData] = UserProfileData(RendomTokenfetch);
  const hiddenuid = localStorage.getItem("userid");

  const navigate = useNavigate();
  const notification = {
    // message: "teodosii@react-notifications-component",
    insert: "top",
    container: "bottom-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      showIcon: true,
      onScreen: true,
    },
    onRemoval: () => {
      window.location.reload();
    },
  };

  const [error, setError] = useState({});

  // Refs for all inputs
  const fnameRef = useRef(ProfileuserData?.firstname || "");
  const lnameRef = useRef(ProfileuserData?.lastname || "");
  const emailRef = useRef(ProfileuserData?.email || "");
  const numberRef = useRef(ProfileuserData?.number || "");
  const facebookRef = useRef(ProfileuserData?.facebook_link || "");
  const instagramRef = useRef(ProfileuserData?.instagram_link || "");
  const twitterRef = useRef(ProfileuserData?.twitter_link || "");
  const linkdeinRef = useRef(ProfileuserData?.linkedin_link || "");
  const addressRef = useRef(ProfileuserData?.address || "");
  const googlemapRef = useRef(ProfileuserData?.googlr_map || "");
  const profileImageRef = useRef();
  const bannerImageRef = useRef();
  const profileimagehiddenRef = useRef(ProfileuserData?.image || "");
  const bannerimagehiddenRef = useRef(ProfileuserData?.banner || "");

  // Handle On Form Submit
  const handleSubmit = (event) => {
    event.preventDefault();

    let fdata = new FormData();
    fdata.append("userid", hiddenuid);
    fdata.append("firstname", fnameRef.current.value);
    fdata.append("lastname", lnameRef.current.value);
    fdata.append("email", emailRef.current.value);
    // fdata.append("number", numberRef.current.value);
    fdata.append("facebook", facebookRef.current.value);
    fdata.append("insta", instagramRef.current.value);
    fdata.append("twitter", twitterRef.current.value);
    fdata.append("linkedin", linkdeinRef.current.value);
    fdata.append("address", addressRef.current.value);
    fdata.append("map", googlemapRef.current.value);
    fdata.append("oldimage", profileimagehiddenRef.current.value);
    fdata.append("oldbanner", bannerimagehiddenRef.current.value);

    // console.log(profileImageRef.current.files[0]);
    // console.log(bannerImageRef.current.files[0]);

    if (profileImageRef.current.files[0]) {
      fdata.append("image", profileImageRef.current.files[0]);
    }
    if (bannerImageRef.current.files[0]) {
      fdata.append("banner", bannerImageRef.current.files[0]);
    }

    axios
      .post("https://yds.hyperofficial.in/api/Webapi/userProfile_edit", fdata, {
        headers: {
          Authorization: RendomTokenfetch,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log(response.data);
        if (
          fnameRef.current.value &&
          lnameRef.current.value &&
          numberRef.current.value &&
          emailRef.current.value
        ) {
          if (response.data.Status === "0") {
            // console.log(response.data);
            // alert(response.data.msg);
            Store.addNotification({
              ...notification,
              title: response.data.msg,
              type: "danger",
            });
            setError(ProfileEditValidation(response.data.form_validation));
          }
          if (response.data.Status === "1") {
            // console.log(response.data);
            Store.addNotification({
              ...notification,
              title: response.data.msg,
              type: "success",
            });
            navigate("/profile-edit");
          }
        } else {
          // console.log(response.data);
          setError(ProfileEditValidation(response.data.form_validation));
        }
      });
    // .catch((error) => {
    //   alert(error);
    // });
  };

  return (
    <>
      <div className="page-wrapper dashboard mm-page mm-slideout" id="mm-0">
        <Sidebar ShowUserSideBar={ShowUserSideBar} />
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="walletheading">
              <p className="Leaddetailsbraudcrume">
                <Link to="/profile" className="serctionBradcrume">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>{" "}
                  Profile
                </Link>
                <span> / Profile-Edit</span>
              </p>
            </div>
            {/* <div className="walletheading">
              <h4 className="PageTitleWallet">Profile Edit</h4>
            </div> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-content py-4">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="row">
                          <div className="form-group col-lg-6 col-md-12 mb-2">
                            <label>
                              First Name<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              ref={fnameRef}
                              defaultValue={ProfileuserData?.firstname || ""}
                            />
                            {error.firstname && (
                              <span className="text-danger">
                                * {error.firstname}
                              </span>
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12 mb-2">
                            <label>
                              Last Name <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              ref={lnameRef}
                              defaultValue={ProfileuserData?.lastname || ""}
                            />
                            {error.lastname && (
                              <span className="text-danger">
                                * {error.lastname}
                              </span>
                            )}
                          </div>

                          <div className="form-group col-lg-6 col-md-12 mb-2">
                            <label>
                              Number <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              ref={numberRef}
                              defaultValue={ProfileuserData?.number || ""}
                              readOnly
                            />
                          </div>

                          <div className="form-group col-lg-6 col-md-12 mb-2">
                            <label>
                              Email ID <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              ref={emailRef}
                              defaultValue={ProfileuserData?.email || ""}
                            />
                            {error.email && (
                              <span className="text-danger">
                                * {error.email}
                              </span>
                            )}
                          </div>
                          <div className="form-group col-lg-6 col-md-12 mb-2">
                            <label>Profile Image</label>
                            <div
                              className="input-group"
                              style={{ marginTop: "4px" }}
                            >
                              <input
                                className="form-control"
                                type="file"
                                accept=".png,.jpg,.jpeg"
                                ref={profileImageRef}
                              />
                              {ProfileuserData.image && (
                                <Link
                                  to={`${ProfileuserData.base_url}${ProfileuserData.image}`}
                                >
                                  <img
                                    src={`${ProfileuserData.base_url}${ProfileuserData.image}`}
                                    style={{
                                      width: "36px !important",
                                      height: "36px",
                                      marginleft: "10px !important",
                                      padding: "0px !important",
                                    }}
                                    alt="profileImg"
                                  />
                                </Link>
                              )}

                              <input
                                type="hidden"
                                ref={profileimagehiddenRef}
                                defaultValue={ProfileuserData?.image || ""}
                              />
                            </div>
                          </div>

                          <div className="form-group col-lg-6 col-md-12 mb-2">
                            <label>Banner Image</label>
                            <div
                              className="input-group"
                              style={{ marginTop: "4px" }}
                            >
                              <input
                                className="form-control"
                                type="file"
                                accept=".png,.jpg,.jpeg"
                                ref={bannerImageRef}
                              />
                              {ProfileuserData.banner && (
                                <Link
                                  to={`${ProfileuserData.base_url}${ProfileuserData.banner}`}
                                >
                                  <img
                                    src={`${ProfileuserData.base_url}${ProfileuserData.banner}`}
                                    style={{
                                      width: "36px !important",
                                      height: "36px",
                                      marginleft: "10px !important",
                                      padding: "0px !important",
                                    }}
                                    alt="bannerimg"
                                  />
                                </Link>
                              )}

                              <input
                                type="hidden"
                                ref={bannerimagehiddenRef}
                                defaultValue={ProfileuserData?.banner || ""}
                              />
                            </div>
                          </div>

                          <div className="form-group col-lg-6 col-md-6 mb-2">
                            <label>Facebook Link</label>
                            <input
                              className="form-control"
                              type="text"
                              ref={facebookRef}
                              defaultValue={
                                ProfileuserData?.facebook_link || ""
                              }
                            />
                          </div>

                          <div className="form-group col-lg-6 col-md-6 mb-2">
                            <label>Instagram Link</label>
                            <input
                              className="form-control"
                              type="text"
                              ref={instagramRef}
                              defaultValue={
                                ProfileuserData?.instagram_link || ""
                              }
                            />
                          </div>

                          <div className="form-group col-lg-6 col-md-6 mb-2">
                            <label>Twitter Link</label>
                            <input
                              className="form-control"
                              type="text"
                              ref={twitterRef}
                              defaultValue={ProfileuserData?.twitter_link || ""}
                            />
                          </div>

                          <div className="form-group col-lg-6 col-md-6 mb-2">
                            <label>Linkedin Link</label>
                            <input
                              className="form-control"
                              type="text"
                              ref={linkdeinRef}
                              defaultValue={
                                ProfileuserData?.linkedin_link || ""
                              }
                            />
                          </div>

                          <div className="form-group col-lg-6 col-md-12 ">
                            <label>Address</label>
                            <textarea
                              className="form-control"
                              rows="2"
                              ref={addressRef}
                              defaultValue={ProfileuserData?.address || ""}
                            />
                          </div>

                          <div className="form-group col-lg-6 col-md-12">
                            <label>Google Map</label>
                            <textarea
                              className="form-control"
                              rows="2"
                              ref={googlemapRef}
                              defaultValue={ProfileuserData?.googlr_map || ""}
                            />
                          </div>

                          <div className="form-group col-lg-12 col-md-12 text-center mt-3">
                            <button
                              className="AllsiteButton mt-2"
                              type="submit"
                              name="submit"
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="deshboard_footer">
          <DeshBoardFooter RendomTokenfetch={RendomTokenfetch} />
        </div>
      </div>
    </>
  );
}
