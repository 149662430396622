import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import WebsiteSetting, { AllServicesCategory, BlogLanguage } from "../FetchAPI";
import HeaderProfileMenu from "../Deshboard/HeaderProfileMenu";
import ShowImagePath from "../ImportantFunction";
import "./Header.css";

export default function Header({ HandleUserSidebar, RendomTokenfetch }) {
  const navigaṭe = useNavigate();

  const NotMathURL = /[’/`~!#*$@%+=.,^&(){}[\]|;:”<>?\\]/g;
  const patthnameURL = useLocation();
  useEffect(() => {
    if (NotMathURL.test(patthnameURL.search)) {
      navigaṭe("/page-not-found");
      window.location.reload();
    }
  });

  // const [RendomTokenfetch] = RendomTokenData();
  const [webSetting] = WebsiteSetting(RendomTokenfetch);
  const [languagedata] = BlogLanguage();
  const [AllserviceCat] = AllServicesCategory(RendomTokenfetch);
  const ImageShowimage = ShowImagePath(
    webSetting.file_path,
    webSetting.header_logo
  );
  const Imagefavicon = ShowImagePath(webSetting.file_path, webSetting.fevicon);
  // console.log(ImageShowimage);

  const [isOpen, setIsopen] = useState(false);
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const [auth, setAuth] = useState();
  useEffect(() => {
    const Auth = localStorage.getItem("userid");
    setAuth(Auth);
  }, [auth]);
  // console.log(auth);

  // if (auth === null) {
  //   navigate("/register");
  // }
  const logout = () => {
    localStorage.clear();
    localStorage.removeItem("userid");
    navigaṭe("/login");
  };

  const Headerdropdown = () => {
    let showDrown;
    if (localStorage.getItem("userid") === null) {
      showDrown = (
        <div className="btn-box">
          <Link to="/login" className="theme-btn btn-style-three call-modal">
            Login / Register
          </Link>
        </div>
      );
    } else {
      showDrown = (
        <HeaderProfileMenu
          Logout={logout}
          HandleUserSidebar={HandleUserSidebar}
          RendomTokenfetch={RendomTokenfetch}
        />
      );
    }
    return showDrown;
  };

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          type="image/x-icon"
          href={webSetting.fevicon && Imagefavicon}
        />
      </Helmet>
      {/* Main Header */}
      {/* <span className="header-span"></span> */}
      <header className="main-header  header-shaddow">
        <div className="container-fluid">
          <div className="main-box">
            <div className="nav-outer">
              <div className="logo-box">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={webSetting.header_logo && ImageShowimage}
                      alt=""
                      title=""
                    />
                  </Link>
                </div>
              </div>

              <nav className="nav main-menu">
                <ul className="navigation" id="navbar">
                  <li>
                    <NavLink className="NavLinkActive" exact="true" to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="has-mega-menu" id="has-mega-menu">
                    <NavLink className="NavLinkActive" exact="true" to="/about">
                      About
                    </NavLink>
                  </li>
                  <li className="dropdown">
                    <NavLink
                      className="NavLinkActive"
                      exact="true"
                      to="/services"
                    >
                    Services <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </NavLink>

                    <ul>
                      {AllserviceCat &&
                        AllserviceCat.map((value, index) => (
                          <li key={index}>
                            <NavLink to={`/service/${value.cat_slug}`}>
                              {value.category}
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </li>
                  {/* <li className="">
                    <NavLink className="NavLinkActive" exact="true" to="/blog">
                      Blogs
                    </NavLink>
                  </li> */}
                  <li className="dropdown">
                    <NavLink className="NavLinkActive" exact="true" to="/blog">
                      Blogs{" "}
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    </NavLink>

                    <ul>
                      {languagedata &&
                        languagedata.map((value, index) => (
                          <li key={index}>
                            <NavLink to={`/blogs/${value.language}`}>
                              {value.long_title}
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li className="">
                    <NavLink
                      className="NavLinkActive"
                      exact="true"
                      to="/contact"
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="outer-box">{<Headerdropdown />}</div>
          </div>
        </div>

        <div className="mobile-header">
          <div className="logo">
            {" "}
            <Link to="/">
              <img
                src={webSetting.header_logo && ImageShowimage}
                alt=""
                title=""
              />
            </Link>
          </div>

          <div className="nav-outer clearfix">
            <div className="outer-box">
              {<Headerdropdown />}
              {/* <button id="toggle-user-sidebar" onClick={HandleUserSidebar}>
                {ProfileuserData.image !== "" ? (
                  <img
                    src={`${ProfileuserData.base_url}${ProfileuserData.image}`}
                    alt="User Profile"
                    title="User"
                    className="thumb"
                  />
                ) : (
                  <img
                    src="images/resource/candidate-1.png"
                    alt="User Profile"
                    title="User"
                    className="thumb"
                  />
                )}
              </button> */}

              <button
                onClick={ToggleSidebar}
                className="mobile-nav-toggler navbar-trigger"
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>

        <nav
          className={`mobileMenu nav main-menu sidebar ${
            isOpen === true ? "active" : ""
          }`}
        >
          <div className="sd-header">
            <Link to="/">
              <img
                src={webSetting.header_logo && ImageShowimage}
                alt=""
                title=""
                style={{ width: "160px" }}
              />
            </Link>
            <div className="btn btn-primary sidebarBtn" onClick={ToggleSidebar}>
              <i className="fa fa-times"></i>
            </div>
          </div>
          <ul className="navigation" id="navbar">
            <li>
              <NavLink className="NavLinkActive" exact="true" to="/">
                Home
              </NavLink>
            </li>
            <li className="has-mega-menu" id="has-mega-menu">
              <NavLink className="NavLinkActive" exact="true" to="/about">
                About
              </NavLink>
            </li>
            <li className="dropdown">
             
                <NavLink className="NavLinkActive" exact="true" to="/services">
                  Services  <i className="mobileDorpdogal fa fa-caret-down" aria-hidden="true"></i>
                </NavLink>
              
              <ul>
                {AllserviceCat &&
                  AllserviceCat.map((value, index) => (
                    <li key={index}>
                      <NavLink to={`/service/${value.cat_slug}`}>
                        {value.category}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </li>
            <li className="dropdown">
              
                <NavLink className="NavLinkActive" exact="true" to="/blog">
                  Blogs <i className="mobileDorpdogal fa fa-caret-down" aria-hidden="true"></i>
                </NavLink>
              
              <ul>
                {languagedata &&
                  languagedata.map((value, index) => (
                    <li key={index}>
                      <NavLink to={`/blogs/${value.language}`}>
                        {value.long_title}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </li>
            <li className="">
              <NavLink className="NavLinkActive" exact="true" to="/contact">
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
      {/* End Main Header */}
    </>
  );
}
