
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Site_url = "https://vipin.hyperofficial.in/";
const Base_url = Site_url + "Master/api/";
const userID = localStorage.getItem("userid");
const WebsiteAPIurl = 'https://yds.hyperofficial.in/api/Webapi/';

// const API_URL = process.env.REACT_APP_SITEURL;

/* =======User Data=========*/
const UserProfileData = (RendomTokenfetch) => {
  const [userData, setuserData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  // console.log(userID);
  useEffect(() => {
    if (!userID) {
      setError("User ID not found in localStorage");
      return;
    }

    const FetchUserData = async () => {
      setLoading(true);
      let fdata = new FormData();
      fdata.append("userID", userID);
      try {
        await axios
          .post(`${WebsiteAPIurl}userdata`, fdata, {
            headers: {
              Authorization: RendomTokenfetch,
            },
          })
          .then((response) => {
            // console.log(response.data);
            if (response.data.Status === "1") {
              setuserData(response.data.userdata);
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    FetchUserData();
  }, [RendomTokenfetch]);
  return [userData, loading, error];
};

/* =======Website Setting=========*/
const WebsiteSetting = (RendomTokenfetch) => {
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const Fetchwebsite = async () => {
      await axios
        .get(`${WebsiteAPIurl}website_setting`, {
          headers: {
            Authorization: RendomTokenfetch,
          },
        })
        .then((res) => {
            //  console.log(res.data);
          if (res.data.Status === "1") {
            setwebSetting(res.data.sitedata);
          }
        });
    };
    Fetchwebsite();
  }, [RendomTokenfetch]);
  return [webSetting];
};

/* =======All Service Category Data=========*/
const AllServicesCategory = (RendomTokenfetch) => {
  const [serviceCat, setserviceCat] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    const FetchcatData = async () => {
      try {
        await axios
          .get(`${WebsiteAPIurl}getallcategory`, {
            headers: {
              Authorization: RendomTokenfetch,
            },
          })
          .then((response) => {
            // console.log('API Response:', response.data);
            if (response.data.Status === "1") {
              setserviceCat(response.data.catdata);
            } else {
              // console.error("Failed to fetch categories:", response.data.Message);
            }
          });
      } catch (err) {
        setError(err.message);
        // console.error('Error:', err.response ? err.response.data : err.message);
      }
    };

    FetchcatData();
  }, [RendomTokenfetch]);
  return [serviceCat, error];
};

/* =======ServiceCatWise Data=========*/
const ServiceCatWise = (RendomTokenfetch, category) => {
  const [servicecat, setservicecat] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const FetchcatService = async () => {
      setLoading(true);
      let fdata = new FormData();
      fdata.append("slug", category);
      try {
        await axios
          .post(
            `${WebsiteAPIurl}get_allservice`,
            fdata,
            {
              headers: {
                Authorization: RendomTokenfetch,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.Status === "1") {
              setservicecat(response.data.servicedata);
            }
            if (response.data.Status === "0") {
              setservicecat("");
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    FetchcatService();
  }, [RendomTokenfetch, category]);
  return [servicecat, loading, error];
};

/* =======Single Cat name Data=========*/
const SingleCatName = (RendomTokenfetch, category) => {
  const [CatName, setCatName] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const Fetchcatname = async () => {
      setLoading(true);
      let fdata = new FormData();
      fdata.append("slug", category);
      try {
        await axios
          .post(
            `${WebsiteAPIurl}getRowcategory`,
            fdata,
            {
              headers: {
                Authorization: RendomTokenfetch,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.Status === "1") {
              setCatName(response.data.catdata);
            }
            if (response.data.Status === "0") {
              setCatName("");
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    Fetchcatname();
  }, [RendomTokenfetch, category]);
  return [CatName, loading, error];
};

/* =======Services Details=========*/
const ServicesDetails = (RendomTokenfetch, serviceSlug) => {
  const [servicedetails, setservicedetails] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const FetcServicesDetails = async () => {
      setLoading(true);
      let fdata = new FormData();
      fdata.append("slug", serviceSlug);
      try {
        await axios
          .post(
            `${WebsiteAPIurl}serviceDetails`,
            fdata,
            {
              headers: {
                Authorization: RendomTokenfetch,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.Status === "1") {
              setservicedetails(response.data.servicedata);
            }
            if (response.data.Status === "0") {
              setservicedetails("");
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    FetcServicesDetails();
  }, [RendomTokenfetch, serviceSlug]);
  return [servicedetails, loading, error];
};
/* =======Home homesetting=========*/
const Homesetting = (RendomTokenfetch) => {
  const [SettingHome, setSettingHome] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const FetcServicesDetails = async () => {
      setLoading(true);
      let fdata = new FormData();
      fdata.append("status", "1");
      try {
        await axios
          .post(
            `${WebsiteAPIurl}homepageSetting`,
            fdata,
            {
              headers: {
                Authorization: RendomTokenfetch,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.Status === "1") {
              setSettingHome(response.data.homedata);
            }
            if (response.data.Status === "0") {
              setSettingHome("");
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    FetcServicesDetails();
  }, [RendomTokenfetch]);
  return [SettingHome, loading, error];
};

/* =======Category All Services =========*/
const AllCatserviceSection = (
  RendomTokenfetch,
  catid,
  servicelimit,
  limittype
) => {
  const [Sectionservice, setSectionservice] = useState([]);
  const [SerCat, setSerCat] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const FetcServicesDetails = async () => {
      setLoading(true);
      let fdata = new FormData();
      fdata.append("catid", catid);
      fdata.append("servicelimit", servicelimit);
      fdata.append("limittype", limittype);
      try {
        await axios
          .post(
            `${WebsiteAPIurl}allcatservice`,
            fdata,
            {
              headers: {
                Authorization: RendomTokenfetch,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.Status === "1") {
              setSectionservice(response.data.servicedata);
              setSerCat(response.data.catdata);
            }
            if (response.data.Status === "0") {
              setSectionservice("");
              setSerCat("");
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    FetcServicesDetails();
  }, [RendomTokenfetch, catid, limittype, servicelimit]);
  return [Sectionservice, SerCat, loading, error];
};

/* =======All Payment Method =========*/
const AllPaymentMode = (RendomTokenfetch) => {
  const [Paymentmode, setPaymentmode] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const Fetcpaymentmod = async () => {
      setLoading(true);
      try {
        await axios
          .get(`${WebsiteAPIurl}allpayment_method`, {
            headers: {
              Authorization: RendomTokenfetch,
            },
          })
          .then((response) => {
            // console.log(response.data);
            if (response.data.Status === "1") {
              setPaymentmode(response.data.pmodedata);
            }
            if (response.data.Status === "0") {
              setPaymentmode("");
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    Fetcpaymentmod();
  }, [RendomTokenfetch]);
  return [Paymentmode, loading, error];
};

/* =======All Leads =========*/
const AllserviceLeads = (RendomTokenfetch, servicelimit, limittype) => {
  const [Userlead, setUserlead] = useState([]);
  const [Status, setStatus] = useState("0");
  const [msg, setmsg] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const FetchUserlead = async () => {
      setLoading(true);
      let fdata = new FormData();
      fdata.append("user_id", userID);
      fdata.append("limit", servicelimit);
      fdata.append("limittype", limittype);
      try {
        await axios
          .post(`${WebsiteAPIurl}allLeads`, fdata, {
            headers: {
              Authorization: RendomTokenfetch,
            },
          })
          .then((response) => {
            // console.log(response.data);
            if (response.data.Status === "1") {
              setUserlead(response.data.leaddata);
              setStatus(response.data.Status);
            }
            if (response.data.Status === "0") {
              setUserlead("");
              setStatus(response.data.Status);
              setmsg(response.data.message);
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    FetchUserlead();
  }, [RendomTokenfetch, limittype, servicelimit]);
  return [Userlead, Status, msg, loading, error];
};

/* =======All Leads Details=========*/
const AllLeadsdetails = (RendomTokenfetch, leadid) => {
  const [leaddetails, setleaddetails] = useState([]);
  const [leadComment, setleadComment] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const FetchUserlead = async () => {
      setLoading(true);
      let fdata = new FormData();
      fdata.append("user_id", userID);
      fdata.append("lead_id", leadid);
      try {
        await axios
          .post(`${WebsiteAPIurl}lead_details`, fdata, {
            headers: {
              Authorization: RendomTokenfetch,
            },
          })
          .then((response) => {
            // console.log(response.data);
            if (response.data.Status === "1") {
              setleaddetails(response.data.leaddata);
              setleadComment(response.data.commentdata);
            }
            if (response.data.Status === "0") {
              setleaddetails("");
              setleadComment("");
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    FetchUserlead();
  }, [RendomTokenfetch, leadid]);
  return [leaddetails, leadComment, loading, error];
};
/* =======All Leads Details=========*/
const AllLeadsStatusStepper = (RendomTokenfetch, leadid, servicID) => {
  const [leadStatusStepper, setleadStatusStepper] = useState([]);
  const [StepperLength, setStepperLength] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const FetchUserlead = async () => {
      setLoading(true);
      let fdata = new FormData();
      fdata.append("lead_id", leadid);
      fdata.append("service_id", servicID);
      try {
        await axios
          .post(
            `${WebsiteAPIurl}leadstatusAjex`,
            fdata,
            {
              headers: {
                Authorization: RendomTokenfetch,
              },
            }
          )
          .then((response) => {
            // console.log(response.data.length);s
            if (response.data.Status === "1") {
              setleadStatusStepper(response.data.statusdata);
              setStepperLength(response.data.statusdata.length);
            }
            if (response.data.Status === "0") {
              setleadStatusStepper("");
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    FetchUserlead();
  }, [RendomTokenfetch, leadid, servicID]);
  return [leadStatusStepper, StepperLength, loading, error];
};

/* =======All Deshbord Count=========*/
const AllDeshbordCountData = (RendomTokenfetch) => {
  const [deshbordCount, setdeshbordCount] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!userID) {
      setError("User ID not found in localStorage");
      return;
    }
    const FetchDeshbordCount = async () => {
      setLoading(true);
      let fdata = new FormData();
      fdata.append("userid", userID);
      try {
        await axios
          .post(
            `${WebsiteAPIurl}dashboard_counter`,
            fdata,
            {
              headers: {
                Authorization: RendomTokenfetch,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            if (response.data.Status === "1") {
              setdeshbordCount(response.data.counterdata);
            }
            if (response.data.Status === "0") {
              setdeshbordCount("");
            }
          });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    FetchDeshbordCount();
  }, [RendomTokenfetch]);
  return [deshbordCount, loading, error];
};

/* =======Home Homedefaultserviceplan=========*/
const Homedefaultserviceplan = (defaultServiceid) => {
  const [Defaultservice, setDefaultservice] = useState([]);
  const [Defaultplan, setDefaultplan] = useState([]);
  useEffect(() => {
    const FetchDefaultplan = async () => {
      await axios
        .get(`${Base_url}serviceapi/homedefaultserviceplan/${defaultServiceid}`)
        .then((res) => {
          if (res.data.status === "1") {
            // alert(res.data.msg);
            setDefaultservice(res.data.servicedata);
            setDefaultplan(res.data.serviceplan);
          }
        });
    };
    FetchDefaultplan();
  }, [defaultServiceid]);
  return [Defaultservice, Defaultplan];
};
/* =======Home Plans Tabs=========*/
const HomePlanstabs = () => {
  const [PlanTabsHome, setPlanTabsHome] = useState([]);
  useEffect(() => {
    const FetchPlanTabsHome = async () => {
      await axios.get(`${Base_url}serviceapi/homeplanservice`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setPlanTabsHome(res.data.service);
        }
      });
    };
    FetchPlanTabsHome();
  }, []);
  return [PlanTabsHome];
};
/* =======Home Services Plans=========*/
const HomeServicesPlans = (IDService) => {
  const [HomeServicePlan, setHomeServicePlan] = useState([]);
  useEffect(() => {
    const FetchHomeServicesPlan = async () => {
      await axios
        .get(`${Base_url}serviceapi/planservicedetail/${IDService}`)
        .then((res) => {
          if (res.data.status === "1") {
            // alert(res.data.msg);
            setHomeServicePlan(res.data.serviceplan);
          }
        });
    };
    FetchHomeServicesPlan();
  }, [IDService]);
  return [HomeServicePlan];
};

/* =======All Pages Pageslist=========*/
const Allpageslist = () => {
  const [AllPage, setAllPage] = useState([]);
  useEffect(() => {
    const FetchAllPages = async () => {
      await axios.get(`${Base_url}settingapi/pageslist`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setAllPage(res.data.pageslist);
        }
      });
    };
    FetchAllPages();
  }, []);
  return [AllPage];
};
/* =======All Pages Data/ MetaTags=========*/
const AllPagesDatas = (pageid) => {
  const [AllPageData, setAllPageData] = useState([]);
  useEffect(() => {
    const FetchAllPagesMeta = async () => {
      await axios
        .get(`${Base_url}settingapi/pagedata/${pageid}`)
        .then((res) => {
          if (res.data.status === "1") {
            // alert(res.data.msg);
            setAllPageData(res.data.pagedata);
          }
        });
    };
    FetchAllPagesMeta();
  }, [pageid]);
  return [AllPageData];
};

/* =======Home Blogs=========*/
const HomeBlog = () => {
  const [HomeBlogs, setHomeBlogs] = useState([]);
  const [Loading, setLoading] = useState(false);
  useEffect(() => {
    const FetchHomeBlog = async () => {
      setLoading(true);
      await axios.get(`${Base_url}settingapi/homeblogs`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setHomeBlogs(res.data.blogs);
        }
      });
      setLoading(false);
    };
    FetchHomeBlog();
  }, []);
  return [HomeBlogs, Loading];
};
/* =======BlogPage Blogs=========*/
const BlogPageBlog = () => {
  const [Blogs, setBlogsdata] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    const FetchBlog = async () => {
      setLoading(true);
      await axios.get(`${Base_url}settingapi/blogs`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setBlogsdata(res.data.blogs);
        }
      });
      setLoading(false);
    };
    FetchBlog();
  }, []);
  return [Blogs, Loading];
};

/* =======BlogPage LangBlog=========*/
const BlogPageLangBlog = (langu) => {
  const [LangBlogs, setLangBlogs] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    const FetchLangBlog = async () => {
      setLoading(true);
      await axios
        .get(`${Base_url}settingapi/languageblogs/${langu}`)
        .then((res) => {
          if (res.data.status === "1") {
            // alert(res.data.msg);
            setLangBlogs(res.data.blogs);
          }
        });
      setLoading(false);
    };
    FetchLangBlog();
  }, [langu]);
  return [LangBlogs, Loading];
};

/* =======Blog All Languages=========*/
const BlogLanguage = () => {
  const [Language, setLanguage] = useState([]);
  useEffect(() => {
    const FetchBlogLanguage = async () => {
      await axios.get(`${Base_url}settingapi/alllanguages`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setLanguage(res.data.alllanguages);
        }
      });
    };
    FetchBlogLanguage();
  }, []);
  return [Language];
};
/* =======Blog Languages MetaTags=========*/
const LanguageMetaTags = (langu) => {
  const [LanguageMeta, setLanguageMeta] = useState([]);
  useEffect(() => {
    const FetchLanguageMeta = async () => {
      await axios.get(`${Base_url}settingapi/alllanguages`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          const resData = res.data.alllanguages;
          const resFilter = resData.filter((langcat) => {
            return langcat.language === langu;
          });
          const jsonLang = JSON.parse(JSON.stringify(resFilter[0]));
          setLanguageMeta(jsonLang);
        }
      });
    };
    FetchLanguageMeta();
  }, [langu]);
  return [LanguageMeta];
};

/* =======Blog Details=========*/
const DetailsBlog = (slug) => {
  const navigaṭe = useNavigate();
  const [Blogdetails, setBlogdetails] = useState([]);
  useEffect(() => {
    const FetchBlogDetails = async () => {
      await axios

        .get(`${Base_url}settingapi/blogdetail/${slug}`)
        .then((res) => {
          if (res.data.status === "1") {
            // alert(res.data.msg);
            setBlogdetails(res.data.blogdetail);
          }
          if (res.data.status === "0") {
            // alert(res.data.msg);
            navigaṭe("/page-not-found");
          }
        });
    };
    FetchBlogDetails();
  }, [slug, navigaṭe]);
  return [Blogdetails];
};

/* =======All Services=========*/
const AllServices = () => {
  const [ServicesAll, setServicesAll] = useState([]);
  useEffect(() => {
    const FetcAllServices = async () => {
      await axios.get(`${Base_url}serviceapi/allservice`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setServicesAll(res.data.service);
        }
      });
    };
    FetcAllServices();
  }, []);
  return [ServicesAll];
};
/* =======Footer Services=========*/
const FootServices = () => {
  const [ServicesFooter, setServicesFooter] = useState([]);
  useEffect(() => {
    const FetcFooterServices = async () => {
      await axios.get(`${Base_url}serviceapi/footerservice`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setServicesFooter(res.data.service);
        }
      });
    };
    FetcFooterServices();
  }, []);
  return [ServicesFooter];
};
/* =======Services Plans=========*/
const ServicesPlans = () => {
  const [ServicePlan, setServicePlan] = useState([]);
  useEffect(() => {
    const FetchServicesPlan = async () => {
      await axios.get(`${Base_url}serviceapi/serviceplan`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setServicePlan(res.data.serviceplan);
        }
      });
    };
    FetchServicesPlan();
  }, []);
  return [ServicePlan];
};

export default WebsiteSetting;
export {
  UserProfileData,
  AllServicesCategory,
  ServiceCatWise,
  SingleCatName,
  Homesetting,
  AllCatserviceSection,
  ServicesDetails,
  AllPaymentMode,
  AllserviceLeads,
  AllLeadsdetails,
  AllLeadsStatusStepper,
  AllDeshbordCountData,
  Homedefaultserviceplan,
  HomePlanstabs,
  HomeServicesPlans,
  HomeBlog,
  BlogPageBlog,
  Allpageslist,
  AllPagesDatas,
  BlogPageLangBlog,
  BlogLanguage,
  LanguageMetaTags,
  DetailsBlog,
  AllServices,
  ServicesPlans,
  FootServices,
};
