import React, { useState } from "react";
// import PageBradcrume from "../PageBradcrume";
import EnqueryValidation from "./EnquiryValidation";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import PageMetaData from "../PageMetaData";
import PageBanner2 from "../PageBanner2";

export default function Enquiry() {
  let { planid } = useParams();
  const navigate = useNavigate();
  const notification = {
    // message: "teodosii@react-notifications-component",
    insert: "top",
    container: "bottom-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      showIcon: true,
      onScreen: true,
    },
  };

  const [Data, setData] = useState({
    fname: "",
    email: "",
    number: "",
    message: "",
  });

  const [error, setError] = useState({});

  const handleChange = (e) => {
    setData({ ...Data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(EnqueryValidation(Data));

    let fdata = new FormData();
    fdata.append("service_id", planid);
    fdata.append("name", Data.fname);
    fdata.append("email", Data.email);
    fdata.append("number", Data.number);
    fdata.append("message", Data.message);

    if (Data.fname && Data.email && Data.number && Data.message) {
      axios
        .post(
          "https://vipin.hyperofficial.in/Master/api/settingapi/serviceEnquiry",
          fdata
        )

        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "0") {
            // alert(response.data.msg);
            Store.addNotification({
              ...notification,
              title: response.data.msg,
              type: "danger",
            });
          }
          if (response.data.status === "1") {
            Store.addNotification({
              ...notification,
              title: response.data.msg,
              type: "success",
            });
            navigate("/services");
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  return (
    <>
      <PageMetaData PageId="13" />
      <PageBanner2 PageId="13" />
      {/* <PageBradcrume Pagename="Enquery" pagetitle="enquery" /> */}

      <section className="enquiry-section section_padding_top section_padding_bottom bgc-home20">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="sidebar-widget contact-widget bg-white px-0">
                <div className="widget-content px-4">
                  <div className="default-form">
                    <h4 className="widget-title mb-3">Get Expert Advice</h4>
                    <form onSubmit={handleSubmit}>
                      <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="hidden"
                            name="serviceid"
                            value={planid}
                          />
                          <label>Name :</label>
                          <input
                            type="text"
                            name="fname"
                            value={Data.fname}
                            onChange={handleChange}
                            placeholder="Your Full Name"
                          />
                          {error.fname && (
                            <span className="text-danger">* {error.fname}</span>
                          )}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Email Address:</label>
                          <input
                            type="email"
                            name="email"
                            value={Data.email}
                            onChange={handleChange}
                            placeholder="Email Address"
                          />
                          {error.email && (
                            <span className="text-danger">* {error.email}</span>
                          )}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Mobile Number:</label>
                          <input
                            type="number"
                            name="number"
                            value={Data.number}
                            onChange={handleChange}
                            placeholder="Your Number"
                          />
                          {error.number && (
                            <span className="text-danger">
                              * {error.number}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Message:</label>
                          <textarea
                            className="darma"
                            name="message"
                            value={Data.message}
                            onChange={handleChange}
                            placeholder="Your Message"
                            rows="2"
                          />
                          {error.message && (
                            <span className="text-danger">
                              * {error.message}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 form-group text-center m-auto">
                          <button
                            className="btn-style-three theme-btn"
                            type="submit"
                            name="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12">
              <iframe
                width="100%"
                height="450"
                src="https://www.youtube.com/embed/wBQauoc1bj0?si=xYaSjAJZ4XaUFumx"
                title="video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
