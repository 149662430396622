import React from "react";
import Sidebar from "./Sidebar";
import DeshBoardFooter from "./DeshBoardFooter";

export default function LeadAdd({ ShowUserSideBar, RendomTokenfetch }) {
  return (
    <>
      <div className="page-wrapper dashboard mm-page mm-slideout" id="mm-0">
        <Sidebar ShowUserSideBar={ShowUserSideBar} />
        <section className="user-dashboard">
          <div className="dashboard-outer">
            <div className="row">
              <div className="col-lg-12">
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title">
                      <h3>Lead Add</h3>
                    </div>
                    <div className="widget-content">
                      <form className="default-form">
                        <div className="row">
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label htmlFor="name">Name</label>
                            <input
                              id="name"
                              type="text"
                              name="name"
                              placeholder="Invisionn"
                            />
                          </div>

                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Mobile Number</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="creativelayers"
                            />
                          </div>

                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Whatsapp Number</label>
                            <input type="text" name="name" />
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Employee</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Neetu Mam</option>
                              <option value="">Payal Mam</option>
                              <option value="">Neelam Mam</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Category</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">It Care</option>
                              <option value="">Home Care</option>
                              <option value="">Document</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Service</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Name change</option>
                              <option value="">Website Develpment</option>
                              <option value="">Court Marrage</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Sub Service</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Sub Service</option>
                              <option value="">Sub Service</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>State</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Rajasthan</option>
                              <option value="">Dehli</option>
                              <option value="">Assam</option>
                              <option value="">Punjab</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>City</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Jaipur</option>
                              <option value="">Sikar</option>
                              <option value="">Kota</option>
                              <option value="">Chomun</option>
                              <option value="">Ajmer</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Vendor Given Work</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Hyper Software</option>
                              <option value="">Sub Service</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Vendor Agent</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Yes</option>
                              <option value="">No</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Vendor Request</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Yes</option>
                              <option value="">No</option>
                            </select>
                          </div>

                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Client Quotation</label>
                            <input type="number" name="name" />
                          </div>

                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Lead Status</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Busy</option>
                              <option value="">In Progress</option>
                              <option value="">Not Started</option>
                              <option value="">Panding</option>
                              <option value="">Work Sent to Clinet</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Source</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Instagram</option>
                              <option value="">Whatsapp</option>
                              <option value="">twitter</option>
                              <option value="">Facebook</option>
                              <option value="">Direct Call</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Invoices</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Outgoing</option>
                              <option value="">Incoming</option>
                            </select>
                          </div>
                          <div className="form-group col-lg-4 col-md-6 col-sm-12">
                            <label>Status Priority</label>
                            <select className="chosen-select">
                              <option value="">Choose</option>
                              <option value="">Urgent</option>
                              <option value="">High</option>
                              <option value="">Medium</option>
                              <option value="">Low</option>
                            </select>
                          </div>

                          <div className="form-group col-lg-12 col-md-12">
                            <label>Description</label>
                            <textarea></textarea>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 text-center mb-3">
                          <button className="theme-btn btn-style-three submitbtn">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="deshboard_footer">
          <DeshBoardFooter RendomTokenfetch={RendomTokenfetch} />
        </div>
      </div>
      
    </>
  );
}
