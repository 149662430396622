import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  //   Button,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import FormStepOne from "./FormStepOne";
import FormStepTwo from "./FormStepTwo";
import FormStepThree from "./FormStepThree";
import PageBradcrume from "../PageBradcrume";
import "./UserSubission.css";

// const steps = ["Personal Information", "Address Details", "Confirmation"];
const steps = [
  { label: "Personal Info", sublabel: "Enter Your Details", icon: "fa-user-o" },
  {
    label: "Account Details",
    sublabel: "Setup Account Details",
    icon: "fa-file-text-o",
  },
  {
    label: "Education",
    sublabel: "Education Details",
    icon: "fa-graduation-cap",
  },
  {
    label: "Work Experience",
    sublabel: "Experience Details",
    icon: "fa-briefcase",
  },
];

const StyledStepIcon = styled("div")(({ theme, active, completed }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  backgroundColor: active || completed ? "#1976d2" : "#e0e0e0",
  color: active || completed ? "#fff" : "#000",
  fontSize: "18px",
}));

export default function UserFormSubmission({ RendomTokenfetch }) {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
  });

  const handleNext = (newValues) => {
    setFormValues({ ...formValues, ...newValues });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    console.log("Form submitted successfully:", formValues);
    setActiveStep(steps.length);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <FormStepOne values={formValues} onNext={handleNext} />;
      case 1:
        return (
          <FormStepTwo
            values={formValues}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 2:
        return (
          <FormStepThree
            values={formValues}
            onBack={handleBack}
            onSubmit={handleSubmit}
          />
        );
      default:
        return "Unknown step";
    }
  };
  return (
    <>
      <PageBradcrume
        Pagename="User Varification"
        pagetitle1="user-submission"
      />

      <section
        className={`job-categories section_padding_top section_padding_bottom `}
      >
        <div className="auto-container">
          <div className="row wow fadeInUp">
            <Box sx={{ width: "100%", margin: "auto", marginTop: "20px" }}>
              <Stepper
                activeStep={activeStep}
                sx={{
                  borderBottom: "1px solid #bdbdbd",
                  paddingBottom: "20px",
                }}
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      icon={
                        <StyledStepIcon
                          active={index === activeStep}
                          completed={index < activeStep}
                        >
                          <i className={`fa ${step.icon}`}></i>
                        </StyledStepIcon>
                      }
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          letterSpacing: "0.9px",
                          lineHeight: "16px",
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "13px", letterSpacing: "0.9px" }}
                      >
                        {step.sublabel}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length ? (
                <Typography variant="h5" sx={{ mt: 3, textAlign: "center" }}>
                  All steps completed. Thank you!
                </Typography>
              ) : (
                <Box
                  className="usersubmissionform"
                  sx={{ width: "100%", margin: "auto", padding: "50px" }}
                >
                  {getStepContent(activeStep)}
                </Box>
              )}
            </Box>
          </div>
        </div>
      </section>
    </>
  );
}
