import React, { useRef, useState } from "react";
import Sidebar from "./Sidebar";
import { AllPaymentMode } from "../FetchAPI";
import "./wallet.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import DeshBoardFooter from "./DeshBoardFooter";

export default function Wallet({ ShowUserSideBar, RendomTokenfetch }) {
  const [Paymentmodedata] = AllPaymentMode(RendomTokenfetch);
  // console.log(Paymentmodedata);
  const hiddenuid = localStorage.getItem("userid");

  const navigate = useNavigate();
  const notification = {
    // message: "teodosii@react-notifications-component",
    insert: "top",
    container: "bottom-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      showIcon: true,
      onScreen: true,
    },
    onRemoval: () => {
      window.location.reload();
    },
  };

  const amountRef = useRef(null);
  const commentRef = useRef(null);
  const transactionIdRef = useRef(null);
  const documentImageRef = useRef(null);

  const [paymentMode, setpaymentMode] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (e) => {
    setpaymentMode(e.target.value);
    const selectedValue = e.target.value;
    const selectedMode = Paymentmodedata.find(
      (val) => val.id === selectedValue
    );
    if (selectedMode) {
      setIsVisible(selectedMode.show_status === "1");
    }
  };

  // console.log(isVisible);
  //console.log(paymentMode);

  // Handle On Form Submit
  const handleSubmit = (event) => {
    event.preventDefault();

    let fdata = new FormData();
    fdata.append("userid", hiddenuid);
    fdata.append("paymentMode", paymentMode);
    fdata.append("amount", amountRef.current.value);
    fdata.append("comment", commentRef.current.value);

    if (isVisible) {
      fdata.append("transactionId", transactionIdRef.current.value);

      if (documentImageRef.current.files[0]) {
        fdata.append("image", documentImageRef.current.files[0]);
      }
    }
    axios
      .post("https://yds.hyperofficial.in/api/Webapi/userProfile_edit", fdata, {
        headers: {
          Authorization: RendomTokenfetch,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log(response.data);
        if (paymentMode && amountRef.current.value) {
          if (response.data.Status === "0") {
            Store.addNotification({
              ...notification,
              title: response.data.msg,
              type: "danger",
            });
            // setError(ProfileEditValidation(response.data.form_validation));
          }
          if (response.data.Status === "1") {
            // console.log(response.data);
            Store.addNotification({
              ...notification,
              title: response.data.msg,
              type: "success",
            });
            navigate("/wallet");
          }
        } else {
          // console.log(response.data);
          // setError(ProfileEditValidation(response.data.form_validation));
        }
      });
    // .catch((error) => {
    //   alert(error);
    // });
  };

  return (
    <>
      <div className="page-wrapper dashboard mm-page mm-slideout" id="mm-0">
        <Sidebar ShowUserSideBar={ShowUserSideBar} />

        <section className="user-dashboard transaction-history">
          <div className="dashboard-outer dashboardouter">
            <div className="walletheading">
              <h4 className="PageTitleWallet">Wallet</h4>
              <h5>
                <b>Totel Amount ₹ 2546</b>
              </h5>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-7 col-md-8  mb-5">
                <div className="card height-equal card_wallet border-unset">
                  <div className="card-body custom-input">
                    <form
                      onSubmit={handleSubmit}
                      className="row g-3 needs-validation custom-input"
                      noValidate=""
                    >
                      <div className="col-12">
                        <label className="form-label" htmlFor="paymentMode">
                          Payment Mode<span className="text-danger">*</span>
                        </label>
                        <select
                          className="chosen-select form-control"
                          id="paymentMode"
                          onChange={handleChange}
                          name="paymentMode"
                        >
                          <option value="">Choose</option>
                          {Paymentmodedata &&
                            Paymentmodedata.map((val, index) => (
                              <option key={index} value={val.id}>
                                {val.payment_mode}
                              </option>
                            ))}
                        </select>
                      </div>
                      {isVisible && (
                        <>
                          <div className="col-12">
                            <label
                              className="form-label"
                              htmlFor="transactionId"
                            >
                              Transaction ID
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              id="transactionId"
                              type="text"
                              required
                              ref={transactionIdRef}
                              defaultValue={""}
                            />
                          </div>
                          <div className="col-12">
                            <label className="form-label" htmlFor="amount">
                              Document<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              accept=".png,.jpg,.jpeg"
                              ref={documentImageRef}
                            />
                          </div>
                        </>
                      )}

                      <div className="col-12">
                        <label className="form-label" htmlFor="amount">
                          Amount<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="amount"
                          type="number"
                          required
                          ref={amountRef}
                          defaultValue={""}
                        />
                      </div>

                      <div className="col-12">
                        <label className="form-label" htmlFor="comment">
                          Comment
                        </label>
                        <textarea
                          className="form-control"
                          id="comment"
                          rows="2"
                          ref={commentRef}
                          defaultValue={""}
                        ></textarea>
                      </div>

                      <div className="col-12 text-center">
                        <button
                          className="AllsiteButton"
                          type="submit"
                          name="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-5 col-md-4  mb-5">
                <div className="wallet-box">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <p>Bank Name: ICICI Bank</p>
                      <p>Account Number: 387201000991</p>
                      <p>IFSC: ICIC0003872</p>
                      <p>MICR: 732229103</p>
                      <p>UPI: 4546732229103</p>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <img
                        src="images/qr-code.png"
                        alt=""
                        className="qrcodeimg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ls-widget">
                  <div className="tabs-box">
                    <div className="widget-title">
                      <h4>Transaction History</h4>
                    </div>

                    <div className="widget-content">
                      <div className="">
                        <table className="default-table manage-job-table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Amount</th>
                              <th>Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>49865</td>
                              <td>30-11-2024 12.23PM</td>
                              <td>
                                <span className="badge bg-primary">
                                  Panding
                                </span>
                              </td>
                            </tr>

                            <tr>
                              <td>2</td>
                              <td>56489965</td>
                              <td>30-11-2024 12.23PM</td>
                              <td>
                                <span className="badge bg-success">
                                  Approve
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>5345</td>
                              <td>30-11-2024 12.23PM</td>
                              <td>
                                <span className="badge bg-danger">
                                  Rejected
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="deshboard_footer">
          <DeshBoardFooter RendomTokenfetch={RendomTokenfetch} />
        </div>
      </div>
    </>
  );
}
