import * as React from "react";
import { Link } from "react-router-dom";
import LeadCommentBtn from "./LeadCommentBtn";

function LeadBox({ leaddata }) {
  // console.log(leaddata);
  return (
    <>
      <div className="ServiceBookingBox candidate-block-four at-v7  col-xl-12 col-lg-12 col-md-12  mb-3">
        <div className="TOPCATSERVIE">
          <p className="topcatsername">
            {leaddata.category} / {leaddata.service}
          </p>
        </div>
        <div className="inner-box text-start">
          <Link to={`/service-booking/${leaddata.id}`}>
            <button className="bookmark-btn at-slv6">
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </Link>
          <div className="d-sm-flex align-items-center imageBoxLead">
            <Link to={`/service-booking/${leaddata.id}`}>
              <span className="thumb mx-0">
                {leaddata.service_icon !== "" ? (
                  <img src={leaddata.service_icon} alt="" />
                ) : (
                  <img src="images/resource/candidate-1.png" alt="" />
                )}
              </span>
            </Link>
            <div className=" ms-sm-2 leadnametacskBox">
              <h3 className="name">
                <Link to={`/service-booking/${leaddata.id}`}>{leaddata.name}</Link>
              </h3>
              <ul className="job-info justify-content-start at-clv7 mt-1">
                <li className="ms-0 ps-0">Ticket no #{leaddata.id}</li>
              </ul>
            </div>
          </div>
          <div className="d-sm-flex align-items-center justify-content-sm-between mt10">
            <ul className="Leadtags justify-content-start  mb-md-0">
              <li className="LeadtagsLi">
                <p>Date</p>
                <span className="">{leaddata.date}</span>
              </li>
              <li className="LeadtagsLi">
                <p>Status</p>
                <span className="badge bg-danger">{leaddata.status}</span>
              </li>
              {/* <li className="LeadtagsLi">
                <p>Category</p>
                <span className="">{leaddata.category}</span>
              </li>
              <li className="LeadtagsLi">
                <p>Service</p>
                <span className="">{leaddata.service}</span>
              </li> */}
            </ul>
            <div className="Leadboxbtn d-flex">
              <LeadCommentBtn leadComment={leaddata.commentdata} />
              <Link
                to={`/service-booking/${leaddata.id}`}
                className="DefaultHoverbtn mx-2"
              >
                View
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadBox;
